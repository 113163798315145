import React from 'react';

export const InstVideo = () => {
	return (
		<div className="carouselVideo">
			<video controls loop className='videoHug' >
				<source src="https://d3r4ipxr51o1re.cloudfront.net/INSTITUTIONAL_VIDEO.mp4" type="video/mp4"/>
			</video> 
		</div>   
	);
};

