/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const asyncProcess = /* GraphQL */ `
  mutation AsyncProcess($values: AWSJSON) {
    asyncProcess(values: $values)
  }
`;
export const bankEc = /* GraphQL */ `
  mutation BankEc {
    bankEc
  }
`;
export const bridgelambda = /* GraphQL */ `
  mutation Bridgelambda($values: AWSJSON) {
    bridgelambda(values: $values)
  }
`;
export const completeTx = /* GraphQL */ `
  mutation CompleteTx($values: AWSJSON) {
    completeTx(values: $values)
  }
`;
export const configProvider = /* GraphQL */ `
  mutation ConfigProvider($values: AWSJSON) {
    configProvider(values: $values)
  }
`;
export const contactUs = /* GraphQL */ `
  mutation ContactUs($values: AWSJSON) {
    contactUs(values: $values)
  }
`;
export const createFileUploadBank = /* GraphQL */ `
  mutation CreateFileUploadBank(
    $condition: ModelFileUploadBankConditionInput
    $input: CreateFileUploadBankInput!
  ) {
    createFileUploadBank(condition: $condition, input: $input) {
      createdAt
      fileName
      fileType
      id
      isDownload
      location
      processedAt
      processedBy
      type
      updatedAt
      __typename
    }
  }
`;
export const createMBBlinkCostByPrice = /* GraphQL */ `
  mutation CreateMBBlinkCostByPrice(
    $condition: ModelMBBlinkCostByPriceConditionInput
    $input: CreateMBBlinkCostByPriceInput!
  ) {
    createMBBlinkCostByPrice(condition: $condition, input: $input) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const createMBBlinkPay = /* GraphQL */ `
  mutation CreateMBBlinkPay(
    $condition: ModelMBBlinkPayConditionInput
    $input: CreateMBBlinkPayInput!
  ) {
    createMBBlinkPay(condition: $condition, input: $input) {
      blink {
        blinkAcquired
        blinkAvailable
        blinkPrice
        blinkSettingID
        createdAt
        currency
        deletedAt
        id
        isPromotional
        updatedAt
        userID
        __typename
      }
      blinkID
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBBlinkSettings = /* GraphQL */ `
  mutation CreateMBBlinkSettings(
    $condition: ModelMBBlinkSettingsConditionInput
    $input: CreateMBBlinkSettingsInput!
  ) {
    createMBBlinkSettings(condition: $condition, input: $input) {
      blinkCost
      blinksByUser {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isoStateCode
      promotionalCount
      settings
      type
      updatedAt
      __typename
    }
  }
`;
export const createMBBlinkUser = /* GraphQL */ `
  mutation CreateMBBlinkUser(
    $condition: ModelMBBlinkUserConditionInput
    $input: CreateMBBlinkUserInput!
  ) {
    createMBBlinkUser(condition: $condition, input: $input) {
      blinkAcquired
      blinkAvailable
      blinkPrice
      blinkSetting {
        blinkCost
        countryID
        createdAt
        currency
        deletedAt
        id
        isoStateCode
        promotionalCount
        settings
        type
        updatedAt
        __typename
      }
      blinkSettingID
      createdAt
      currency
      deletedAt
      id
      isPromotional
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBCard = /* GraphQL */ `
  mutation CreateMBCard(
    $condition: ModelMBCardConditionInput
    $input: CreateMBCardInput!
  ) {
    createMBCard(condition: $condition, input: $input) {
      cardId
      cardNumber
      createdAt
      currencyCode
      description
      expirationDate
      id
      isActive
      isUsedPayment
      label
      nameCard
      payType
      paymentMethod
      paymentMethodCountryID
      transactionType
      updatedAt
      userID
      __typename
    }
  }
`;
export const createMBChargeCountry = /* GraphQL */ `
  mutation CreateMBChargeCountry(
    $condition: ModelMBChargeCountryConditionInput
    $input: CreateMBChargeCountryInput!
  ) {
    createMBChargeCountry(condition: $condition, input: $input) {
      chargeCode
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const createMBCheckbookUser = /* GraphQL */ `
  mutation CreateMBCheckbookUser(
    $condition: ModelMBCheckbookUserConditionInput
    $input: CreateMBCheckbookUserInput!
  ) {
    createMBCheckbookUser(condition: $condition, input: $input) {
      checkbookData
      createdAt
      deletedAt
      id
      updatedAt
      userID
      __typename
    }
  }
`;
export const createMBCode = /* GraphQL */ `
  mutation CreateMBCode(
    $condition: ModelMBCodeConditionInput
    $input: CreateMBCodeInput!
  ) {
    createMBCode(condition: $condition, input: $input) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      isUserUsed
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBContact = /* GraphQL */ `
  mutation CreateMBContact(
    $condition: ModelMBContactConditionInput
    $input: CreateMBContactInput!
  ) {
    createMBContact(condition: $condition, input: $input) {
      code
      codeID
      createdAt
      deletedAt
      id
      invited {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      invitedID
      invitingID
      isFavorite
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      myReceipts
      myShipments
      updatedAt
      __typename
    }
  }
`;
export const createMBCorresponsalChargues = /* GraphQL */ `
  mutation CreateMBCorresponsalChargues(
    $condition: ModelMBCorresponsalCharguesConditionInput
    $input: CreateMBCorresponsalCharguesInput!
  ) {
    createMBCorresponsalChargues(condition: $condition, input: $input) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const createMBCorresponsalSettings = /* GraphQL */ `
  mutation CreateMBCorresponsalSettings(
    $condition: ModelMBCorresponsalSettingsConditionInput
    $input: CreateMBCorresponsalSettingsInput!
  ) {
    createMBCorresponsalSettings(condition: $condition, input: $input) {
      createdAt
      id
      updatedAt
      userId
      value
      __typename
    }
  }
`;
export const createMBCorresponsalTx = /* GraphQL */ `
  mutation CreateMBCorresponsalTx(
    $condition: ModelMBCorresponsalTxConditionInput
    $input: CreateMBCorresponsalTxInput!
  ) {
    createMBCorresponsalTx(condition: $condition, input: $input) {
      amount
      amountwc
      code
      commission
      corresponsal {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      corresponsalID
      createdAt
      customer {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      customerID
      id
      transactionType
      updatedAt
      __typename
    }
  }
`;
export const createMBCountry = /* GraphQL */ `
  mutation CreateMBCountry(
    $condition: ModelMBCountryConditionInput
    $input: CreateMBCountryInput!
  ) {
    createMBCountry(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      charges {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryStateId
      createdAt
      currency
      deletedAt
      id
      isActive
      isDownload
      name
      settings
      showOrder
      taxes {
        nextToken
        __typename
      }
      translate
      type
      updatedAt
      __typename
    }
  }
`;
export const createMBDeletedUser = /* GraphQL */ `
  mutation CreateMBDeletedUser(
    $condition: ModelMBDeletedUserConditionInput
    $input: CreateMBDeletedUserInput!
  ) {
    createMBDeletedUser(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      reason
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBDownloadBlink = /* GraphQL */ `
  mutation CreateMBDownloadBlink(
    $condition: ModelMBDownloadBlinkConditionInput
    $input: CreateMBDownloadBlinkInput!
  ) {
    createMBDownloadBlink(condition: $condition, input: $input) {
      amount
      batchCatch
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      paymentMethodId
      processAt
      stateCode
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBEcpTransaction = /* GraphQL */ `
  mutation CreateMBEcpTransaction(
    $condition: ModelMBEcpTransactionConditionInput
    $input: CreateMBEcpTransactionInput!
  ) {
    createMBEcpTransaction(condition: $condition, input: $input) {
      amount
      charges
      countryReceiverID
      createdAt
      deletedAt
      details
      ecpTransactionID
      id
      isMBUser
      paymentMethod
      paymentType
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      total
      transactionID
      updatedAt
      __typename
    }
  }
`;
export const createMBExponentialBackoff = /* GraphQL */ `
  mutation CreateMBExponentialBackoff(
    $condition: ModelMBExponentialBackoffConditionInput
    $input: CreateMBExponentialBackoffInput!
  ) {
    createMBExponentialBackoff(condition: $condition, input: $input) {
      createdAt
      currentRetry
      deletedAt
      entityId
      expiresAt
      id
      maxRetry
      resourceName
      resourceType
      secondEntityId
      updatedAt
      __typename
    }
  }
`;
export const createMBFinancialData = /* GraphQL */ `
  mutation CreateMBFinancialData(
    $condition: ModelMBFinancialDataConditionInput
    $input: CreateMBFinancialDataInput!
  ) {
    createMBFinancialData(condition: $condition, input: $input) {
      amount
      blinks
      createdAt
      currency
      deletedAt
      id
      isActive
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBInsuredUsers = /* GraphQL */ `
  mutation CreateMBInsuredUsers(
    $condition: ModelMBInsuredUsersConditionInput
    $input: CreateMBInsuredUsersInput!
  ) {
    createMBInsuredUsers(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      id
      insuranceID
      insured
      typeInsurance
      updatedAt
      __typename
    }
  }
`;
export const createMBLifeInsurance = /* GraphQL */ `
  mutation CreateMBLifeInsurance(
    $condition: ModelMBLifeInsuranceConditionInput
    $input: CreateMBLifeInsuranceInput!
  ) {
    createMBLifeInsurance(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const createMBMedInsurance = /* GraphQL */ `
  mutation CreateMBMedInsurance(
    $condition: ModelMBMedInsuranceConditionInput
    $input: CreateMBMedInsuranceInput!
  ) {
    createMBMedInsurance(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const createMBMembershipCategory = /* GraphQL */ `
  mutation CreateMBMembershipCategory(
    $condition: ModelMBMembershipCategoryConditionInput
    $input: CreateMBMembershipCategoryInput!
  ) {
    createMBMembershipCategory(condition: $condition, input: $input) {
      categoryType
      createdAt
      deletedAt
      id
      isActive
      settings
      showOrder
      updatedAt
      __typename
    }
  }
`;
export const createMBMyPaymentMethod = /* GraphQL */ `
  mutation CreateMBMyPaymentMethod(
    $condition: ModelMBMyPaymentMethodConditionInput
    $input: CreateMBMyPaymentMethodInput!
  ) {
    createMBMyPaymentMethod(condition: $condition, input: $input) {
      accountId
      createdAt
      deletedAt
      description
      id
      isActive
      isDeleted
      isUsedPayment
      label
      payType
      paymentMethodCountry {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      paymentMethodCountryID
      settings
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      value
      __typename
    }
  }
`;
export const createMBMyThirdAccountsPay = /* GraphQL */ `
  mutation CreateMBMyThirdAccountsPay(
    $condition: ModelMBMyThirdAccountsPayConditionInput
    $input: CreateMBMyThirdAccountsPayInput!
  ) {
    createMBMyThirdAccountsPay(condition: $condition, input: $input) {
      accountId
      alpha2Code
      alpha3Code
      createdAt
      description
      id
      isActive
      label
      settings
      type
      updatedAt
      userID
      __typename
    }
  }
`;
export const createMBNotification = /* GraphQL */ `
  mutation CreateMBNotification(
    $condition: ModelMBNotificationConditionInput
    $input: CreateMBNotificationInput!
  ) {
    createMBNotification(condition: $condition, input: $input) {
      createdAt
      data
      id
      isRead
      message
      title
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBOwnAccountsTransactions = /* GraphQL */ `
  mutation CreateMBOwnAccountsTransactions(
    $condition: ModelMBOwnAccountsTransactionsConditionInput
    $input: CreateMBOwnAccountsTransactionsInput!
  ) {
    createMBOwnAccountsTransactions(condition: $condition, input: $input) {
      CountryPaymentMethod
      amount
      bankReceiver
      countryReceiver
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const createMBPay = /* GraphQL */ `
  mutation CreateMBPay(
    $condition: ModelMBPayConditionInput
    $input: CreateMBPayInput!
  ) {
    createMBPay(condition: $condition, input: $input) {
      amount
      createdAt
      currency
      deletedAt
      exchangeAmount
      exchangeCurrency
      exchangeRate
      id
      paymentID
      paymentMethod {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      paymentMethodCountry
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBPaymentMethod = /* GraphQL */ `
  mutation CreateMBPaymentMethod(
    $condition: ModelMBPaymentMethodConditionInput
    $input: CreateMBPaymentMethodInput!
  ) {
    createMBPaymentMethod(condition: $condition, input: $input) {
      code
      countries {
        nextToken
        __typename
      }
      createdAt
      deletedAt
      id
      name
      translate
      updatedAt
      __typename
    }
  }
`;
export const createMBPaymentMethodCountry = /* GraphQL */ `
  mutation CreateMBPaymentMethodCountry(
    $condition: ModelMBPaymentMethodCountryConditionInput
    $input: CreateMBPaymentMethodCountryInput!
  ) {
    createMBPaymentMethodCountry(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      availableFromAt
      availableUntilAt
      costCenter {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      costCenterID
      createdAt
      id
      isActive
      isReceipt
      isShipping
      paymentMethod {
        code
        createdAt
        deletedAt
        id
        name
        translate
        updatedAt
        __typename
      }
      paymentMethodID
      paymentTypeCode
      settings
      updatedAt
      users {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createMBReferralCode = /* GraphQL */ `
  mutation CreateMBReferralCode(
    $condition: ModelMBReferralCodeConditionInput
    $input: CreateMBReferralCodeInput!
  ) {
    createMBReferralCode(condition: $condition, input: $input) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      updatedAt
      usedAt
      usedBy
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBSettings = /* GraphQL */ `
  mutation CreateMBSettings(
    $condition: ModelMBSettingsConditionInput
    $input: CreateMBSettingsInput!
  ) {
    createMBSettings(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      deletedAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const createMBSettingsApi = /* GraphQL */ `
  mutation CreateMBSettingsApi(
    $condition: ModelMBSettingsApiConditionInput
    $input: CreateMBSettingsApiInput!
  ) {
    createMBSettingsApi(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const createMBSettingsCard = /* GraphQL */ `
  mutation CreateMBSettingsCard(
    $condition: ModelMBSettingsCardConditionInput
    $input: CreateMBSettingsCardInput!
  ) {
    createMBSettingsCard(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      isActive
      isUseToPay
      settings
      type
      typeCard
      updatedAt
      __typename
    }
  }
`;
export const createMBTaxCountry = /* GraphQL */ `
  mutation CreateMBTaxCountry(
    $condition: ModelMBTaxCountryConditionInput
    $input: CreateMBTaxCountryInput!
  ) {
    createMBTaxCountry(condition: $condition, input: $input) {
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      taxCode
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const createMBThirdAccountsPay = /* GraphQL */ `
  mutation CreateMBThirdAccountsPay(
    $condition: ModelMBThirdAccountsPayConditionInput
    $input: CreateMBThirdAccountsPayInput!
  ) {
    createMBThirdAccountsPay(condition: $condition, input: $input) {
      createdAt
      id
      settings
      thirdCountryPartyID
      updatedAt
      __typename
    }
  }
`;
export const createMBThirdAccountsTransactions = /* GraphQL */ `
  mutation CreateMBThirdAccountsTransactions(
    $condition: ModelMBThirdAccountsTransactionsConditionInput
    $input: CreateMBThirdAccountsTransactionsInput!
  ) {
    createMBThirdAccountsTransactions(condition: $condition, input: $input) {
      CountryPaymentMethod
      amount
      bankReceiver
      batchCatch
      countryReceiver
      createdAt
      currency
      id
      paymentMethod
      processAt
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const createMBThirdPartyCountries = /* GraphQL */ `
  mutation CreateMBThirdPartyCountries(
    $condition: ModelMBThirdPartyCountriesConditionInput
    $input: CreateMBThirdPartyCountriesInput!
  ) {
    createMBThirdPartyCountries(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      isUsedPayment
      settings
      updatedAt
      __typename
    }
  }
`;
export const createMBTransaction = /* GraphQL */ `
  mutation CreateMBTransaction(
    $condition: ModelMBTransactionConditionInput
    $input: CreateMBTransactionInput!
  ) {
    createMBTransaction(condition: $condition, input: $input) {
      amount
      amountDeposit
      charges
      codeID
      createdAt
      currency
      currencyDeposit
      deletedAt
      downloads {
        nextToken
        __typename
      }
      id
      isConfirm
      isReceipt
      message
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      requestMessage
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      taxes
      txStatus
      txType
      txValues
      type
      typeTransaction
      updatedAt
      __typename
    }
  }
`;
export const createMBTransactionPay = /* GraphQL */ `
  mutation CreateMBTransactionPay(
    $condition: ModelMBTransactionPayConditionInput
    $input: CreateMBTransactionPayInput!
  ) {
    createMBTransactionPay(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const createMBUser = /* GraphQL */ `
  mutation CreateMBUser(
    $condition: ModelMBUserConditionInput
    $input: CreateMBUserInput!
  ) {
    createMBUser(condition: $condition, input: $input) {
      acceptedPromotionalInfo
      acceptedRequestBlink
      address
      alpha2Code
      alpha3Code
      avatarUrl
      birthDate
      checkEmail
      checkPhone
      city
      cognitoUserId
      country
      createdAt
      currency
      deletedAt
      deviceToken
      email
      fullName
      id
      idcheckbookID
      identificationBackUrl
      identificationNumber
      identificationType
      identificationUrl
      isAvailabilityTx
      isDeleted
      isMFA
      isTerms
      isUpdateAccount
      isUsedMoneyBlinkAmount
      locale
      myBlinks {
        nextToken
        __typename
      }
      myPayments {
        nextToken
        __typename
      }
      nickname
      oldAvatarUrl
      oldIdentificationBackUrl
      oldIdentificationUrl
      phoneNumber
      role
      smsStatus
      state
      type
      updatedAt
      zipCode
      __typename
    }
  }
`;
export const createMBUserBatch = /* GraphQL */ `
  mutation CreateMBUserBatch(
    $condition: ModelMBUserBatchConditionInput
    $input: CreateMBUserBatchInput!
  ) {
    createMBUserBatch(condition: $condition, input: $input) {
      cardID
      createdAt
      deletedAt
      id
      settings
      updatedAt
      userID
      username
      __typename
    }
  }
`;
export const createMBUserCodeRegistration = /* GraphQL */ `
  mutation CreateMBUserCodeRegistration(
    $condition: ModelMBUserCodeRegistrationConditionInput
    $input: CreateMBUserCodeRegistrationInput!
  ) {
    createMBUserCodeRegistration(condition: $condition, input: $input) {
      attributes
      codeGenerated
      createdAt
      deletedAt
      email
      id
      nickname
      updatedAt
      __typename
    }
  }
`;
export const createMBUserMemberShip = /* GraphQL */ `
  mutation CreateMBUserMemberShip(
    $condition: ModelMBUserMemberShipConditionInput
    $input: CreateMBUserMemberShipInput!
  ) {
    createMBUserMemberShip(condition: $condition, input: $input) {
      categoryType
      createdAt
      currentStatus
      deletedAt
      id
      isActive
      settings
      totalBlinksSent
      totalReferral
      totalReferralAccepted
      updatedAt
      userID
      wasBenefited
      __typename
    }
  }
`;
export const createMBUserPartiesAML = /* GraphQL */ `
  mutation CreateMBUserPartiesAML(
    $condition: ModelMBUserPartiesAMLConditionInput
    $input: CreateMBUserPartiesAMLInput!
  ) {
    createMBUserPartiesAML(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      partyID
      settings
      status
      updatedAt
      userID
      __typename
    }
  }
`;
export const createMBUserVerified = /* GraphQL */ `
  mutation CreateMBUserVerified(
    $condition: ModelMBUserVerifiedConditionInput
    $input: CreateMBUserVerifiedInput!
  ) {
    createMBUserVerified(condition: $condition, input: $input) {
      apiResultCode
      apiResultMessage
      counterRequested
      createdAt
      deletedAt
      emailSent
      errorCode
      id
      status
      transactionID
      typeVerification
      updatedAt
      userID
      verified
      verifiedBy
      workflowID
      __typename
    }
  }
`;
export const createOrValidateCode = /* GraphQL */ `
  mutation CreateOrValidateCode($values: AWSJSON) {
    createOrValidateCode(values: $values)
  }
`;
export const createPay = /* GraphQL */ `
  mutation CreatePay($payInput: AWSJSON) {
    createPay(payInput: $payInput)
  }
`;
export const createTx = /* GraphQL */ `
  mutation CreateTx($values: AWSJSON) {
    createTx(values: $values)
  }
`;
export const deleteFileUploadBank = /* GraphQL */ `
  mutation DeleteFileUploadBank(
    $condition: ModelFileUploadBankConditionInput
    $input: DeleteFileUploadBankInput!
  ) {
    deleteFileUploadBank(condition: $condition, input: $input) {
      createdAt
      fileName
      fileType
      id
      isDownload
      location
      processedAt
      processedBy
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteMBBlinkCostByPrice = /* GraphQL */ `
  mutation DeleteMBBlinkCostByPrice(
    $condition: ModelMBBlinkCostByPriceConditionInput
    $input: DeleteMBBlinkCostByPriceInput!
  ) {
    deleteMBBlinkCostByPrice(condition: $condition, input: $input) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const deleteMBBlinkPay = /* GraphQL */ `
  mutation DeleteMBBlinkPay(
    $condition: ModelMBBlinkPayConditionInput
    $input: DeleteMBBlinkPayInput!
  ) {
    deleteMBBlinkPay(condition: $condition, input: $input) {
      blink {
        blinkAcquired
        blinkAvailable
        blinkPrice
        blinkSettingID
        createdAt
        currency
        deletedAt
        id
        isPromotional
        updatedAt
        userID
        __typename
      }
      blinkID
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBBlinkSettings = /* GraphQL */ `
  mutation DeleteMBBlinkSettings(
    $condition: ModelMBBlinkSettingsConditionInput
    $input: DeleteMBBlinkSettingsInput!
  ) {
    deleteMBBlinkSettings(condition: $condition, input: $input) {
      blinkCost
      blinksByUser {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isoStateCode
      promotionalCount
      settings
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteMBBlinkUser = /* GraphQL */ `
  mutation DeleteMBBlinkUser(
    $condition: ModelMBBlinkUserConditionInput
    $input: DeleteMBBlinkUserInput!
  ) {
    deleteMBBlinkUser(condition: $condition, input: $input) {
      blinkAcquired
      blinkAvailable
      blinkPrice
      blinkSetting {
        blinkCost
        countryID
        createdAt
        currency
        deletedAt
        id
        isoStateCode
        promotionalCount
        settings
        type
        updatedAt
        __typename
      }
      blinkSettingID
      createdAt
      currency
      deletedAt
      id
      isPromotional
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBCard = /* GraphQL */ `
  mutation DeleteMBCard(
    $condition: ModelMBCardConditionInput
    $input: DeleteMBCardInput!
  ) {
    deleteMBCard(condition: $condition, input: $input) {
      cardId
      cardNumber
      createdAt
      currencyCode
      description
      expirationDate
      id
      isActive
      isUsedPayment
      label
      nameCard
      payType
      paymentMethod
      paymentMethodCountryID
      transactionType
      updatedAt
      userID
      __typename
    }
  }
`;
export const deleteMBChargeCountry = /* GraphQL */ `
  mutation DeleteMBChargeCountry(
    $condition: ModelMBChargeCountryConditionInput
    $input: DeleteMBChargeCountryInput!
  ) {
    deleteMBChargeCountry(condition: $condition, input: $input) {
      chargeCode
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const deleteMBCheckbookUser = /* GraphQL */ `
  mutation DeleteMBCheckbookUser(
    $condition: ModelMBCheckbookUserConditionInput
    $input: DeleteMBCheckbookUserInput!
  ) {
    deleteMBCheckbookUser(condition: $condition, input: $input) {
      checkbookData
      createdAt
      deletedAt
      id
      updatedAt
      userID
      __typename
    }
  }
`;
export const deleteMBCode = /* GraphQL */ `
  mutation DeleteMBCode(
    $condition: ModelMBCodeConditionInput
    $input: DeleteMBCodeInput!
  ) {
    deleteMBCode(condition: $condition, input: $input) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      isUserUsed
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBContact = /* GraphQL */ `
  mutation DeleteMBContact(
    $condition: ModelMBContactConditionInput
    $input: DeleteMBContactInput!
  ) {
    deleteMBContact(condition: $condition, input: $input) {
      code
      codeID
      createdAt
      deletedAt
      id
      invited {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      invitedID
      invitingID
      isFavorite
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      myReceipts
      myShipments
      updatedAt
      __typename
    }
  }
`;
export const deleteMBCorresponsalChargues = /* GraphQL */ `
  mutation DeleteMBCorresponsalChargues(
    $condition: ModelMBCorresponsalCharguesConditionInput
    $input: DeleteMBCorresponsalCharguesInput!
  ) {
    deleteMBCorresponsalChargues(condition: $condition, input: $input) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const deleteMBCorresponsalSettings = /* GraphQL */ `
  mutation DeleteMBCorresponsalSettings(
    $condition: ModelMBCorresponsalSettingsConditionInput
    $input: DeleteMBCorresponsalSettingsInput!
  ) {
    deleteMBCorresponsalSettings(condition: $condition, input: $input) {
      createdAt
      id
      updatedAt
      userId
      value
      __typename
    }
  }
`;
export const deleteMBCorresponsalTx = /* GraphQL */ `
  mutation DeleteMBCorresponsalTx(
    $condition: ModelMBCorresponsalTxConditionInput
    $input: DeleteMBCorresponsalTxInput!
  ) {
    deleteMBCorresponsalTx(condition: $condition, input: $input) {
      amount
      amountwc
      code
      commission
      corresponsal {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      corresponsalID
      createdAt
      customer {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      customerID
      id
      transactionType
      updatedAt
      __typename
    }
  }
`;
export const deleteMBCountry = /* GraphQL */ `
  mutation DeleteMBCountry(
    $condition: ModelMBCountryConditionInput
    $input: DeleteMBCountryInput!
  ) {
    deleteMBCountry(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      charges {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryStateId
      createdAt
      currency
      deletedAt
      id
      isActive
      isDownload
      name
      settings
      showOrder
      taxes {
        nextToken
        __typename
      }
      translate
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteMBDeletedUser = /* GraphQL */ `
  mutation DeleteMBDeletedUser(
    $condition: ModelMBDeletedUserConditionInput
    $input: DeleteMBDeletedUserInput!
  ) {
    deleteMBDeletedUser(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      reason
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBDownloadBlink = /* GraphQL */ `
  mutation DeleteMBDownloadBlink(
    $condition: ModelMBDownloadBlinkConditionInput
    $input: DeleteMBDownloadBlinkInput!
  ) {
    deleteMBDownloadBlink(condition: $condition, input: $input) {
      amount
      batchCatch
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      paymentMethodId
      processAt
      stateCode
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBEcpTransaction = /* GraphQL */ `
  mutation DeleteMBEcpTransaction(
    $condition: ModelMBEcpTransactionConditionInput
    $input: DeleteMBEcpTransactionInput!
  ) {
    deleteMBEcpTransaction(condition: $condition, input: $input) {
      amount
      charges
      countryReceiverID
      createdAt
      deletedAt
      details
      ecpTransactionID
      id
      isMBUser
      paymentMethod
      paymentType
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      total
      transactionID
      updatedAt
      __typename
    }
  }
`;
export const deleteMBExponentialBackoff = /* GraphQL */ `
  mutation DeleteMBExponentialBackoff(
    $condition: ModelMBExponentialBackoffConditionInput
    $input: DeleteMBExponentialBackoffInput!
  ) {
    deleteMBExponentialBackoff(condition: $condition, input: $input) {
      createdAt
      currentRetry
      deletedAt
      entityId
      expiresAt
      id
      maxRetry
      resourceName
      resourceType
      secondEntityId
      updatedAt
      __typename
    }
  }
`;
export const deleteMBFinancialData = /* GraphQL */ `
  mutation DeleteMBFinancialData(
    $condition: ModelMBFinancialDataConditionInput
    $input: DeleteMBFinancialDataInput!
  ) {
    deleteMBFinancialData(condition: $condition, input: $input) {
      amount
      blinks
      createdAt
      currency
      deletedAt
      id
      isActive
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBInsuredUsers = /* GraphQL */ `
  mutation DeleteMBInsuredUsers(
    $condition: ModelMBInsuredUsersConditionInput
    $input: DeleteMBInsuredUsersInput!
  ) {
    deleteMBInsuredUsers(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      id
      insuranceID
      insured
      typeInsurance
      updatedAt
      __typename
    }
  }
`;
export const deleteMBLifeInsurance = /* GraphQL */ `
  mutation DeleteMBLifeInsurance(
    $condition: ModelMBLifeInsuranceConditionInput
    $input: DeleteMBLifeInsuranceInput!
  ) {
    deleteMBLifeInsurance(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const deleteMBMedInsurance = /* GraphQL */ `
  mutation DeleteMBMedInsurance(
    $condition: ModelMBMedInsuranceConditionInput
    $input: DeleteMBMedInsuranceInput!
  ) {
    deleteMBMedInsurance(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const deleteMBMembershipCategory = /* GraphQL */ `
  mutation DeleteMBMembershipCategory(
    $condition: ModelMBMembershipCategoryConditionInput
    $input: DeleteMBMembershipCategoryInput!
  ) {
    deleteMBMembershipCategory(condition: $condition, input: $input) {
      categoryType
      createdAt
      deletedAt
      id
      isActive
      settings
      showOrder
      updatedAt
      __typename
    }
  }
`;
export const deleteMBMyPaymentMethod = /* GraphQL */ `
  mutation DeleteMBMyPaymentMethod(
    $condition: ModelMBMyPaymentMethodConditionInput
    $input: DeleteMBMyPaymentMethodInput!
  ) {
    deleteMBMyPaymentMethod(condition: $condition, input: $input) {
      accountId
      createdAt
      deletedAt
      description
      id
      isActive
      isDeleted
      isUsedPayment
      label
      payType
      paymentMethodCountry {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      paymentMethodCountryID
      settings
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      value
      __typename
    }
  }
`;
export const deleteMBMyThirdAccountsPay = /* GraphQL */ `
  mutation DeleteMBMyThirdAccountsPay(
    $condition: ModelMBMyThirdAccountsPayConditionInput
    $input: DeleteMBMyThirdAccountsPayInput!
  ) {
    deleteMBMyThirdAccountsPay(condition: $condition, input: $input) {
      accountId
      alpha2Code
      alpha3Code
      createdAt
      description
      id
      isActive
      label
      settings
      type
      updatedAt
      userID
      __typename
    }
  }
`;
export const deleteMBNotification = /* GraphQL */ `
  mutation DeleteMBNotification(
    $condition: ModelMBNotificationConditionInput
    $input: DeleteMBNotificationInput!
  ) {
    deleteMBNotification(condition: $condition, input: $input) {
      createdAt
      data
      id
      isRead
      message
      title
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBOwnAccountsTransactions = /* GraphQL */ `
  mutation DeleteMBOwnAccountsTransactions(
    $condition: ModelMBOwnAccountsTransactionsConditionInput
    $input: DeleteMBOwnAccountsTransactionsInput!
  ) {
    deleteMBOwnAccountsTransactions(condition: $condition, input: $input) {
      CountryPaymentMethod
      amount
      bankReceiver
      countryReceiver
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteMBPay = /* GraphQL */ `
  mutation DeleteMBPay(
    $condition: ModelMBPayConditionInput
    $input: DeleteMBPayInput!
  ) {
    deleteMBPay(condition: $condition, input: $input) {
      amount
      createdAt
      currency
      deletedAt
      exchangeAmount
      exchangeCurrency
      exchangeRate
      id
      paymentID
      paymentMethod {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      paymentMethodCountry
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBPaymentMethod = /* GraphQL */ `
  mutation DeleteMBPaymentMethod(
    $condition: ModelMBPaymentMethodConditionInput
    $input: DeleteMBPaymentMethodInput!
  ) {
    deleteMBPaymentMethod(condition: $condition, input: $input) {
      code
      countries {
        nextToken
        __typename
      }
      createdAt
      deletedAt
      id
      name
      translate
      updatedAt
      __typename
    }
  }
`;
export const deleteMBPaymentMethodCountry = /* GraphQL */ `
  mutation DeleteMBPaymentMethodCountry(
    $condition: ModelMBPaymentMethodCountryConditionInput
    $input: DeleteMBPaymentMethodCountryInput!
  ) {
    deleteMBPaymentMethodCountry(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      availableFromAt
      availableUntilAt
      costCenter {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      costCenterID
      createdAt
      id
      isActive
      isReceipt
      isShipping
      paymentMethod {
        code
        createdAt
        deletedAt
        id
        name
        translate
        updatedAt
        __typename
      }
      paymentMethodID
      paymentTypeCode
      settings
      updatedAt
      users {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteMBReferralCode = /* GraphQL */ `
  mutation DeleteMBReferralCode(
    $condition: ModelMBReferralCodeConditionInput
    $input: DeleteMBReferralCodeInput!
  ) {
    deleteMBReferralCode(condition: $condition, input: $input) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      updatedAt
      usedAt
      usedBy
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBSettings = /* GraphQL */ `
  mutation DeleteMBSettings(
    $condition: ModelMBSettingsConditionInput
    $input: DeleteMBSettingsInput!
  ) {
    deleteMBSettings(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      deletedAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const deleteMBSettingsApi = /* GraphQL */ `
  mutation DeleteMBSettingsApi(
    $condition: ModelMBSettingsApiConditionInput
    $input: DeleteMBSettingsApiInput!
  ) {
    deleteMBSettingsApi(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const deleteMBSettingsCard = /* GraphQL */ `
  mutation DeleteMBSettingsCard(
    $condition: ModelMBSettingsCardConditionInput
    $input: DeleteMBSettingsCardInput!
  ) {
    deleteMBSettingsCard(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      isActive
      isUseToPay
      settings
      type
      typeCard
      updatedAt
      __typename
    }
  }
`;
export const deleteMBTaxCountry = /* GraphQL */ `
  mutation DeleteMBTaxCountry(
    $condition: ModelMBTaxCountryConditionInput
    $input: DeleteMBTaxCountryInput!
  ) {
    deleteMBTaxCountry(condition: $condition, input: $input) {
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      taxCode
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const deleteMBThirdAccountsPay = /* GraphQL */ `
  mutation DeleteMBThirdAccountsPay(
    $condition: ModelMBThirdAccountsPayConditionInput
    $input: DeleteMBThirdAccountsPayInput!
  ) {
    deleteMBThirdAccountsPay(condition: $condition, input: $input) {
      createdAt
      id
      settings
      thirdCountryPartyID
      updatedAt
      __typename
    }
  }
`;
export const deleteMBThirdAccountsTransactions = /* GraphQL */ `
  mutation DeleteMBThirdAccountsTransactions(
    $condition: ModelMBThirdAccountsTransactionsConditionInput
    $input: DeleteMBThirdAccountsTransactionsInput!
  ) {
    deleteMBThirdAccountsTransactions(condition: $condition, input: $input) {
      CountryPaymentMethod
      amount
      bankReceiver
      batchCatch
      countryReceiver
      createdAt
      currency
      id
      paymentMethod
      processAt
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteMBThirdPartyCountries = /* GraphQL */ `
  mutation DeleteMBThirdPartyCountries(
    $condition: ModelMBThirdPartyCountriesConditionInput
    $input: DeleteMBThirdPartyCountriesInput!
  ) {
    deleteMBThirdPartyCountries(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      isUsedPayment
      settings
      updatedAt
      __typename
    }
  }
`;
export const deleteMBTransaction = /* GraphQL */ `
  mutation DeleteMBTransaction(
    $condition: ModelMBTransactionConditionInput
    $input: DeleteMBTransactionInput!
  ) {
    deleteMBTransaction(condition: $condition, input: $input) {
      amount
      amountDeposit
      charges
      codeID
      createdAt
      currency
      currencyDeposit
      deletedAt
      downloads {
        nextToken
        __typename
      }
      id
      isConfirm
      isReceipt
      message
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      requestMessage
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      taxes
      txStatus
      txType
      txValues
      type
      typeTransaction
      updatedAt
      __typename
    }
  }
`;
export const deleteMBTransactionPay = /* GraphQL */ `
  mutation DeleteMBTransactionPay(
    $condition: ModelMBTransactionPayConditionInput
    $input: DeleteMBTransactionPayInput!
  ) {
    deleteMBTransactionPay(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const deleteMBUser = /* GraphQL */ `
  mutation DeleteMBUser(
    $condition: ModelMBUserConditionInput
    $input: DeleteMBUserInput!
  ) {
    deleteMBUser(condition: $condition, input: $input) {
      acceptedPromotionalInfo
      acceptedRequestBlink
      address
      alpha2Code
      alpha3Code
      avatarUrl
      birthDate
      checkEmail
      checkPhone
      city
      cognitoUserId
      country
      createdAt
      currency
      deletedAt
      deviceToken
      email
      fullName
      id
      idcheckbookID
      identificationBackUrl
      identificationNumber
      identificationType
      identificationUrl
      isAvailabilityTx
      isDeleted
      isMFA
      isTerms
      isUpdateAccount
      isUsedMoneyBlinkAmount
      locale
      myBlinks {
        nextToken
        __typename
      }
      myPayments {
        nextToken
        __typename
      }
      nickname
      oldAvatarUrl
      oldIdentificationBackUrl
      oldIdentificationUrl
      phoneNumber
      role
      smsStatus
      state
      type
      updatedAt
      zipCode
      __typename
    }
  }
`;
export const deleteMBUserBatch = /* GraphQL */ `
  mutation DeleteMBUserBatch(
    $condition: ModelMBUserBatchConditionInput
    $input: DeleteMBUserBatchInput!
  ) {
    deleteMBUserBatch(condition: $condition, input: $input) {
      cardID
      createdAt
      deletedAt
      id
      settings
      updatedAt
      userID
      username
      __typename
    }
  }
`;
export const deleteMBUserCodeRegistration = /* GraphQL */ `
  mutation DeleteMBUserCodeRegistration(
    $condition: ModelMBUserCodeRegistrationConditionInput
    $input: DeleteMBUserCodeRegistrationInput!
  ) {
    deleteMBUserCodeRegistration(condition: $condition, input: $input) {
      attributes
      codeGenerated
      createdAt
      deletedAt
      email
      id
      nickname
      updatedAt
      __typename
    }
  }
`;
export const deleteMBUserMemberShip = /* GraphQL */ `
  mutation DeleteMBUserMemberShip(
    $condition: ModelMBUserMemberShipConditionInput
    $input: DeleteMBUserMemberShipInput!
  ) {
    deleteMBUserMemberShip(condition: $condition, input: $input) {
      categoryType
      createdAt
      currentStatus
      deletedAt
      id
      isActive
      settings
      totalBlinksSent
      totalReferral
      totalReferralAccepted
      updatedAt
      userID
      wasBenefited
      __typename
    }
  }
`;
export const deleteMBUserPartiesAML = /* GraphQL */ `
  mutation DeleteMBUserPartiesAML(
    $condition: ModelMBUserPartiesAMLConditionInput
    $input: DeleteMBUserPartiesAMLInput!
  ) {
    deleteMBUserPartiesAML(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      partyID
      settings
      status
      updatedAt
      userID
      __typename
    }
  }
`;
export const deleteMBUserVerified = /* GraphQL */ `
  mutation DeleteMBUserVerified(
    $condition: ModelMBUserVerifiedConditionInput
    $input: DeleteMBUserVerifiedInput!
  ) {
    deleteMBUserVerified(condition: $condition, input: $input) {
      apiResultCode
      apiResultMessage
      counterRequested
      createdAt
      deletedAt
      emailSent
      errorCode
      id
      status
      transactionID
      typeVerification
      updatedAt
      userID
      verified
      verifiedBy
      workflowID
      __typename
    }
  }
`;
export const handleInsuranceForms = /* GraphQL */ `
  mutation HandleInsuranceForms($values: AWSJSON) {
    handleInsuranceForms(values: $values)
  }
`;
export const invokeLambdaApi = /* GraphQL */ `
  mutation InvokeLambdaApi($values: AWSJSON) {
    invokeLambdaApi(values: $values)
  }
`;
export const manageAuth = /* GraphQL */ `
  mutation ManageAuth($values: AWSJSON) {
    manageAuth(values: $values)
  }
`;
export const managePaymentMethods = /* GraphQL */ `
  mutation ManagePaymentMethods($values: AWSJSON) {
    managePaymentMethods(values: $values)
  }
`;
export const performCorresponsalAccount = /* GraphQL */ `
  mutation PerformCorresponsalAccount($values: AWSJSON) {
    performCorresponsalAccount(values: $values)
  }
`;
export const performUserCoderegistration = /* GraphQL */ `
  mutation PerformUserCoderegistration($values: AWSJSON) {
    performUserCoderegistration(values: $values)
  }
`;
export const purchasePack = /* GraphQL */ `
  mutation PurchasePack($values: AWSJSON) {
    purchasePack(values: $values)
  }
`;
export const rewardsPlans = /* GraphQL */ `
  mutation RewardsPlans($values: AWSJSON) {
    rewardsPlans(values: $values)
  }
`;
export const samasatProvider = /* GraphQL */ `
  mutation SamasatProvider($values: AWSJSON) {
    samasatProvider(values: $values)
  }
`;
export const upDownCash = /* GraphQL */ `
  mutation UpDownCash($values: AWSJSON) {
    upDownCash(values: $values)
  }
`;
export const updateFileUploadBank = /* GraphQL */ `
  mutation UpdateFileUploadBank(
    $condition: ModelFileUploadBankConditionInput
    $input: UpdateFileUploadBankInput!
  ) {
    updateFileUploadBank(condition: $condition, input: $input) {
      createdAt
      fileName
      fileType
      id
      isDownload
      location
      processedAt
      processedBy
      type
      updatedAt
      __typename
    }
  }
`;
export const updateMBBlinkCostByPrice = /* GraphQL */ `
  mutation UpdateMBBlinkCostByPrice(
    $condition: ModelMBBlinkCostByPriceConditionInput
    $input: UpdateMBBlinkCostByPriceInput!
  ) {
    updateMBBlinkCostByPrice(condition: $condition, input: $input) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const updateMBBlinkPay = /* GraphQL */ `
  mutation UpdateMBBlinkPay(
    $condition: ModelMBBlinkPayConditionInput
    $input: UpdateMBBlinkPayInput!
  ) {
    updateMBBlinkPay(condition: $condition, input: $input) {
      blink {
        blinkAcquired
        blinkAvailable
        blinkPrice
        blinkSettingID
        createdAt
        currency
        deletedAt
        id
        isPromotional
        updatedAt
        userID
        __typename
      }
      blinkID
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBBlinkSettings = /* GraphQL */ `
  mutation UpdateMBBlinkSettings(
    $condition: ModelMBBlinkSettingsConditionInput
    $input: UpdateMBBlinkSettingsInput!
  ) {
    updateMBBlinkSettings(condition: $condition, input: $input) {
      blinkCost
      blinksByUser {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isoStateCode
      promotionalCount
      settings
      type
      updatedAt
      __typename
    }
  }
`;
export const updateMBBlinkUser = /* GraphQL */ `
  mutation UpdateMBBlinkUser(
    $condition: ModelMBBlinkUserConditionInput
    $input: UpdateMBBlinkUserInput!
  ) {
    updateMBBlinkUser(condition: $condition, input: $input) {
      blinkAcquired
      blinkAvailable
      blinkPrice
      blinkSetting {
        blinkCost
        countryID
        createdAt
        currency
        deletedAt
        id
        isoStateCode
        promotionalCount
        settings
        type
        updatedAt
        __typename
      }
      blinkSettingID
      createdAt
      currency
      deletedAt
      id
      isPromotional
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBCard = /* GraphQL */ `
  mutation UpdateMBCard(
    $condition: ModelMBCardConditionInput
    $input: UpdateMBCardInput!
  ) {
    updateMBCard(condition: $condition, input: $input) {
      cardId
      cardNumber
      createdAt
      currencyCode
      description
      expirationDate
      id
      isActive
      isUsedPayment
      label
      nameCard
      payType
      paymentMethod
      paymentMethodCountryID
      transactionType
      updatedAt
      userID
      __typename
    }
  }
`;
export const updateMBChargeCountry = /* GraphQL */ `
  mutation UpdateMBChargeCountry(
    $condition: ModelMBChargeCountryConditionInput
    $input: UpdateMBChargeCountryInput!
  ) {
    updateMBChargeCountry(condition: $condition, input: $input) {
      chargeCode
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const updateMBCheckbookUser = /* GraphQL */ `
  mutation UpdateMBCheckbookUser(
    $condition: ModelMBCheckbookUserConditionInput
    $input: UpdateMBCheckbookUserInput!
  ) {
    updateMBCheckbookUser(condition: $condition, input: $input) {
      checkbookData
      createdAt
      deletedAt
      id
      updatedAt
      userID
      __typename
    }
  }
`;
export const updateMBCode = /* GraphQL */ `
  mutation UpdateMBCode(
    $condition: ModelMBCodeConditionInput
    $input: UpdateMBCodeInput!
  ) {
    updateMBCode(condition: $condition, input: $input) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      isUserUsed
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBContact = /* GraphQL */ `
  mutation UpdateMBContact(
    $condition: ModelMBContactConditionInput
    $input: UpdateMBContactInput!
  ) {
    updateMBContact(condition: $condition, input: $input) {
      code
      codeID
      createdAt
      deletedAt
      id
      invited {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      invitedID
      invitingID
      isFavorite
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      myReceipts
      myShipments
      updatedAt
      __typename
    }
  }
`;
export const updateMBCorresponsalChargues = /* GraphQL */ `
  mutation UpdateMBCorresponsalChargues(
    $condition: ModelMBCorresponsalCharguesConditionInput
    $input: UpdateMBCorresponsalCharguesInput!
  ) {
    updateMBCorresponsalChargues(condition: $condition, input: $input) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const updateMBCorresponsalSettings = /* GraphQL */ `
  mutation UpdateMBCorresponsalSettings(
    $condition: ModelMBCorresponsalSettingsConditionInput
    $input: UpdateMBCorresponsalSettingsInput!
  ) {
    updateMBCorresponsalSettings(condition: $condition, input: $input) {
      createdAt
      id
      updatedAt
      userId
      value
      __typename
    }
  }
`;
export const updateMBCorresponsalTx = /* GraphQL */ `
  mutation UpdateMBCorresponsalTx(
    $condition: ModelMBCorresponsalTxConditionInput
    $input: UpdateMBCorresponsalTxInput!
  ) {
    updateMBCorresponsalTx(condition: $condition, input: $input) {
      amount
      amountwc
      code
      commission
      corresponsal {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      corresponsalID
      createdAt
      customer {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      customerID
      id
      transactionType
      updatedAt
      __typename
    }
  }
`;
export const updateMBCountry = /* GraphQL */ `
  mutation UpdateMBCountry(
    $condition: ModelMBCountryConditionInput
    $input: UpdateMBCountryInput!
  ) {
    updateMBCountry(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      charges {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryStateId
      createdAt
      currency
      deletedAt
      id
      isActive
      isDownload
      name
      settings
      showOrder
      taxes {
        nextToken
        __typename
      }
      translate
      type
      updatedAt
      __typename
    }
  }
`;
export const updateMBDeletedUser = /* GraphQL */ `
  mutation UpdateMBDeletedUser(
    $condition: ModelMBDeletedUserConditionInput
    $input: UpdateMBDeletedUserInput!
  ) {
    updateMBDeletedUser(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      reason
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBDownloadBlink = /* GraphQL */ `
  mutation UpdateMBDownloadBlink(
    $condition: ModelMBDownloadBlinkConditionInput
    $input: UpdateMBDownloadBlinkInput!
  ) {
    updateMBDownloadBlink(condition: $condition, input: $input) {
      amount
      batchCatch
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      paymentMethodId
      processAt
      stateCode
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBEcpTransaction = /* GraphQL */ `
  mutation UpdateMBEcpTransaction(
    $condition: ModelMBEcpTransactionConditionInput
    $input: UpdateMBEcpTransactionInput!
  ) {
    updateMBEcpTransaction(condition: $condition, input: $input) {
      amount
      charges
      countryReceiverID
      createdAt
      deletedAt
      details
      ecpTransactionID
      id
      isMBUser
      paymentMethod
      paymentType
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      total
      transactionID
      updatedAt
      __typename
    }
  }
`;
export const updateMBExponentialBackoff = /* GraphQL */ `
  mutation UpdateMBExponentialBackoff(
    $condition: ModelMBExponentialBackoffConditionInput
    $input: UpdateMBExponentialBackoffInput!
  ) {
    updateMBExponentialBackoff(condition: $condition, input: $input) {
      createdAt
      currentRetry
      deletedAt
      entityId
      expiresAt
      id
      maxRetry
      resourceName
      resourceType
      secondEntityId
      updatedAt
      __typename
    }
  }
`;
export const updateMBFinancialData = /* GraphQL */ `
  mutation UpdateMBFinancialData(
    $condition: ModelMBFinancialDataConditionInput
    $input: UpdateMBFinancialDataInput!
  ) {
    updateMBFinancialData(condition: $condition, input: $input) {
      amount
      blinks
      createdAt
      currency
      deletedAt
      id
      isActive
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBInsuredUsers = /* GraphQL */ `
  mutation UpdateMBInsuredUsers(
    $condition: ModelMBInsuredUsersConditionInput
    $input: UpdateMBInsuredUsersInput!
  ) {
    updateMBInsuredUsers(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      id
      insuranceID
      insured
      typeInsurance
      updatedAt
      __typename
    }
  }
`;
export const updateMBLifeInsurance = /* GraphQL */ `
  mutation UpdateMBLifeInsurance(
    $condition: ModelMBLifeInsuranceConditionInput
    $input: UpdateMBLifeInsuranceInput!
  ) {
    updateMBLifeInsurance(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const updateMBMedInsurance = /* GraphQL */ `
  mutation UpdateMBMedInsurance(
    $condition: ModelMBMedInsuranceConditionInput
    $input: UpdateMBMedInsuranceInput!
  ) {
    updateMBMedInsurance(condition: $condition, input: $input) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const updateMBMembershipCategory = /* GraphQL */ `
  mutation UpdateMBMembershipCategory(
    $condition: ModelMBMembershipCategoryConditionInput
    $input: UpdateMBMembershipCategoryInput!
  ) {
    updateMBMembershipCategory(condition: $condition, input: $input) {
      categoryType
      createdAt
      deletedAt
      id
      isActive
      settings
      showOrder
      updatedAt
      __typename
    }
  }
`;
export const updateMBMyPaymentMethod = /* GraphQL */ `
  mutation UpdateMBMyPaymentMethod(
    $condition: ModelMBMyPaymentMethodConditionInput
    $input: UpdateMBMyPaymentMethodInput!
  ) {
    updateMBMyPaymentMethod(condition: $condition, input: $input) {
      accountId
      createdAt
      deletedAt
      description
      id
      isActive
      isDeleted
      isUsedPayment
      label
      payType
      paymentMethodCountry {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      paymentMethodCountryID
      settings
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      value
      __typename
    }
  }
`;
export const updateMBMyThirdAccountsPay = /* GraphQL */ `
  mutation UpdateMBMyThirdAccountsPay(
    $condition: ModelMBMyThirdAccountsPayConditionInput
    $input: UpdateMBMyThirdAccountsPayInput!
  ) {
    updateMBMyThirdAccountsPay(condition: $condition, input: $input) {
      accountId
      alpha2Code
      alpha3Code
      createdAt
      description
      id
      isActive
      label
      settings
      type
      updatedAt
      userID
      __typename
    }
  }
`;
export const updateMBNotification = /* GraphQL */ `
  mutation UpdateMBNotification(
    $condition: ModelMBNotificationConditionInput
    $input: UpdateMBNotificationInput!
  ) {
    updateMBNotification(condition: $condition, input: $input) {
      createdAt
      data
      id
      isRead
      message
      title
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBOwnAccountsTransactions = /* GraphQL */ `
  mutation UpdateMBOwnAccountsTransactions(
    $condition: ModelMBOwnAccountsTransactionsConditionInput
    $input: UpdateMBOwnAccountsTransactionsInput!
  ) {
    updateMBOwnAccountsTransactions(condition: $condition, input: $input) {
      CountryPaymentMethod
      amount
      bankReceiver
      countryReceiver
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const updateMBPay = /* GraphQL */ `
  mutation UpdateMBPay(
    $condition: ModelMBPayConditionInput
    $input: UpdateMBPayInput!
  ) {
    updateMBPay(condition: $condition, input: $input) {
      amount
      createdAt
      currency
      deletedAt
      exchangeAmount
      exchangeCurrency
      exchangeRate
      id
      paymentID
      paymentMethod {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      paymentMethodCountry
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBPaymentMethod = /* GraphQL */ `
  mutation UpdateMBPaymentMethod(
    $condition: ModelMBPaymentMethodConditionInput
    $input: UpdateMBPaymentMethodInput!
  ) {
    updateMBPaymentMethod(condition: $condition, input: $input) {
      code
      countries {
        nextToken
        __typename
      }
      createdAt
      deletedAt
      id
      name
      translate
      updatedAt
      __typename
    }
  }
`;
export const updateMBPaymentMethodCountry = /* GraphQL */ `
  mutation UpdateMBPaymentMethodCountry(
    $condition: ModelMBPaymentMethodCountryConditionInput
    $input: UpdateMBPaymentMethodCountryInput!
  ) {
    updateMBPaymentMethodCountry(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      availableFromAt
      availableUntilAt
      costCenter {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      costCenterID
      createdAt
      id
      isActive
      isReceipt
      isShipping
      paymentMethod {
        code
        createdAt
        deletedAt
        id
        name
        translate
        updatedAt
        __typename
      }
      paymentMethodID
      paymentTypeCode
      settings
      updatedAt
      users {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateMBReferralCode = /* GraphQL */ `
  mutation UpdateMBReferralCode(
    $condition: ModelMBReferralCodeConditionInput
    $input: UpdateMBReferralCodeInput!
  ) {
    updateMBReferralCode(condition: $condition, input: $input) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      updatedAt
      usedAt
      usedBy
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBSettings = /* GraphQL */ `
  mutation UpdateMBSettings(
    $condition: ModelMBSettingsConditionInput
    $input: UpdateMBSettingsInput!
  ) {
    updateMBSettings(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      deletedAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const updateMBSettingsApi = /* GraphQL */ `
  mutation UpdateMBSettingsApi(
    $condition: ModelMBSettingsApiConditionInput
    $input: UpdateMBSettingsApiInput!
  ) {
    updateMBSettingsApi(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const updateMBSettingsCard = /* GraphQL */ `
  mutation UpdateMBSettingsCard(
    $condition: ModelMBSettingsCardConditionInput
    $input: UpdateMBSettingsCardInput!
  ) {
    updateMBSettingsCard(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      isActive
      isUseToPay
      settings
      type
      typeCard
      updatedAt
      __typename
    }
  }
`;
export const updateMBTaxCountry = /* GraphQL */ `
  mutation UpdateMBTaxCountry(
    $condition: ModelMBTaxCountryConditionInput
    $input: UpdateMBTaxCountryInput!
  ) {
    updateMBTaxCountry(condition: $condition, input: $input) {
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      taxCode
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const updateMBThirdAccountsPay = /* GraphQL */ `
  mutation UpdateMBThirdAccountsPay(
    $condition: ModelMBThirdAccountsPayConditionInput
    $input: UpdateMBThirdAccountsPayInput!
  ) {
    updateMBThirdAccountsPay(condition: $condition, input: $input) {
      createdAt
      id
      settings
      thirdCountryPartyID
      updatedAt
      __typename
    }
  }
`;
export const updateMBThirdAccountsTransactions = /* GraphQL */ `
  mutation UpdateMBThirdAccountsTransactions(
    $condition: ModelMBThirdAccountsTransactionsConditionInput
    $input: UpdateMBThirdAccountsTransactionsInput!
  ) {
    updateMBThirdAccountsTransactions(condition: $condition, input: $input) {
      CountryPaymentMethod
      amount
      bankReceiver
      batchCatch
      countryReceiver
      createdAt
      currency
      id
      paymentMethod
      processAt
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const updateMBThirdPartyCountries = /* GraphQL */ `
  mutation UpdateMBThirdPartyCountries(
    $condition: ModelMBThirdPartyCountriesConditionInput
    $input: UpdateMBThirdPartyCountriesInput!
  ) {
    updateMBThirdPartyCountries(condition: $condition, input: $input) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      isUsedPayment
      settings
      updatedAt
      __typename
    }
  }
`;
export const updateMBTransaction = /* GraphQL */ `
  mutation UpdateMBTransaction(
    $condition: ModelMBTransactionConditionInput
    $input: UpdateMBTransactionInput!
  ) {
    updateMBTransaction(condition: $condition, input: $input) {
      amount
      amountDeposit
      charges
      codeID
      createdAt
      currency
      currencyDeposit
      deletedAt
      downloads {
        nextToken
        __typename
      }
      id
      isConfirm
      isReceipt
      message
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      requestMessage
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      taxes
      txStatus
      txType
      txValues
      type
      typeTransaction
      updatedAt
      __typename
    }
  }
`;
export const updateMBTransactionPay = /* GraphQL */ `
  mutation UpdateMBTransactionPay(
    $condition: ModelMBTransactionPayConditionInput
    $input: UpdateMBTransactionPayInput!
  ) {
    updateMBTransactionPay(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const updateMBUser = /* GraphQL */ `
  mutation UpdateMBUser(
    $condition: ModelMBUserConditionInput
    $input: UpdateMBUserInput!
  ) {
    updateMBUser(condition: $condition, input: $input) {
      acceptedPromotionalInfo
      acceptedRequestBlink
      address
      alpha2Code
      alpha3Code
      avatarUrl
      birthDate
      checkEmail
      checkPhone
      city
      cognitoUserId
      country
      createdAt
      currency
      deletedAt
      deviceToken
      email
      fullName
      id
      idcheckbookID
      identificationBackUrl
      identificationNumber
      identificationType
      identificationUrl
      isAvailabilityTx
      isDeleted
      isMFA
      isTerms
      isUpdateAccount
      isUsedMoneyBlinkAmount
      locale
      myBlinks {
        nextToken
        __typename
      }
      myPayments {
        nextToken
        __typename
      }
      nickname
      oldAvatarUrl
      oldIdentificationBackUrl
      oldIdentificationUrl
      phoneNumber
      role
      smsStatus
      state
      type
      updatedAt
      zipCode
      __typename
    }
  }
`;
export const updateMBUserBatch = /* GraphQL */ `
  mutation UpdateMBUserBatch(
    $condition: ModelMBUserBatchConditionInput
    $input: UpdateMBUserBatchInput!
  ) {
    updateMBUserBatch(condition: $condition, input: $input) {
      cardID
      createdAt
      deletedAt
      id
      settings
      updatedAt
      userID
      username
      __typename
    }
  }
`;
export const updateMBUserCodeRegistration = /* GraphQL */ `
  mutation UpdateMBUserCodeRegistration(
    $condition: ModelMBUserCodeRegistrationConditionInput
    $input: UpdateMBUserCodeRegistrationInput!
  ) {
    updateMBUserCodeRegistration(condition: $condition, input: $input) {
      attributes
      codeGenerated
      createdAt
      deletedAt
      email
      id
      nickname
      updatedAt
      __typename
    }
  }
`;
export const updateMBUserMemberShip = /* GraphQL */ `
  mutation UpdateMBUserMemberShip(
    $condition: ModelMBUserMemberShipConditionInput
    $input: UpdateMBUserMemberShipInput!
  ) {
    updateMBUserMemberShip(condition: $condition, input: $input) {
      categoryType
      createdAt
      currentStatus
      deletedAt
      id
      isActive
      settings
      totalBlinksSent
      totalReferral
      totalReferralAccepted
      updatedAt
      userID
      wasBenefited
      __typename
    }
  }
`;
export const updateMBUserPartiesAML = /* GraphQL */ `
  mutation UpdateMBUserPartiesAML(
    $condition: ModelMBUserPartiesAMLConditionInput
    $input: UpdateMBUserPartiesAMLInput!
  ) {
    updateMBUserPartiesAML(condition: $condition, input: $input) {
      createdAt
      deletedAt
      id
      partyID
      settings
      status
      updatedAt
      userID
      __typename
    }
  }
`;
export const updateMBUserVerified = /* GraphQL */ `
  mutation UpdateMBUserVerified(
    $condition: ModelMBUserVerifiedConditionInput
    $input: UpdateMBUserVerifiedInput!
  ) {
    updateMBUserVerified(condition: $condition, input: $input) {
      apiResultCode
      apiResultMessage
      counterRequested
      createdAt
      deletedAt
      emailSent
      errorCode
      id
      status
      transactionID
      typeVerification
      updatedAt
      userID
      verified
      verifiedBy
      workflowID
      __typename
    }
  }
`;
export const validateCode = /* GraphQL */ `
  mutation ValidateCode($values: AWSJSON) {
    validateCode(values: $values)
  }
`;
export const verifyMbUser = /* GraphQL */ `
  mutation VerifyMbUser($values: AWSJSON) {
    verifyMbUser(values: $values)
  }
`;
export const verifyTransactions = /* GraphQL */ `
  mutation VerifyTransactions($values: AWSJSON) {
    verifyTransactions(values: $values)
  }
`;
