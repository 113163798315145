export const THEME_COLORS = {
    GRAY: "#7a7878",
    PRIMARY: '#2FBDEB',
    BACKROUND: '#FFFFFF',
    SURFACE: '#FFFFFF',
    ACCENT: '#DA2164',
    ERROR: '#E33F3B',
    TEXT: '#000000',
    ONSURFACE: '#4EA750',
    INFO: '#4EA750',
    DISABLED: '#97A19A',
    PLACEHOLDER: '#383938',
    BACKDROP: 'transparent',
    NOTIFICATION: '#4EA750',
    BTNCOLOR: '#52C1E0',
    HEADR: '#52C1E0',
    BTNDISABLED: '#EEEEEE',
    WARN: '#E98A3C',
    DARKBLUE: '#',
    GRAYLIGTH: '#5A5B5C',
    GRAYLIGTH2: '#f7f7f7',
    GREENLIGTH: '#D2DB20',
    GRAYDARK:'#3e3a3a'
}
