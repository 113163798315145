import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './helpers/ScrollToTop';
import { createRoot } from 'react-dom/client';
import { Amplify, I18n } from 'aws-amplify';
import awsmobile from './aws-exports';
import './tailwind.css';
import { es_ES } from './translate/es';
import { NextUIProvider } from '@nextui-org/react';
Amplify.configure(awsmobile);
I18n.setLanguage('es');
I18n.putVocabulariesForLanguage('es', es_ES);
I18n.putVocabulariesForLanguage('en', es_ES);
const root = document.getElementById('root');
const reactRoot = createRoot(root);

reactRoot.render(
	<BrowserRouter>
		<NextUIProvider>
			<ScrollToTop />
			<App />
		</NextUIProvider>
	</BrowserRouter>
);

