import { API, Auth, Storage } from "aws-amplify";
import {  invokeLambdaApi, manageAuth } from "../graphql/mutations";
import { ManageAuthTypes } from "../constants/constants";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { byReferralCodeInfo } from "../otherQueries/otherQueries";

export const handleGetCurrentPath = () => {
    return window.location.pathname;
};
export const handleGetFirstPath = () => {
    let paths = window.location.pathname.split('/');
    return paths[1];
};

export const handleGetURLQueryParams = (query) => {
    try {
        const queryParameters = new URLSearchParams(window.location.search)
        return queryParameters.get(query)
    } catch (e) {
        return null;
    }
}
export const handleCallBackendApi = async (_bodyReq) => {
    // if (await handleGetCurrentAuthUser()) {
    //     const currentUser = await Auth.currentAuthenticatedUser()
    //     if (currentUser?.authIdentifierTag == SignInMethodTags.MONEYBLINKS_NUVEM && _bodyReq.authMode == GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS) {
    //         _bodyReq.authMode = GRAPHQL_AUTH_MODE.AWS_IAM
    //     }
    // }
    return await API.graphql(_bodyReq);
}


export const handleSendVerificationOTP = async (reqBody) => {
    try {
        let {
            data: { manageAuth: responseAuth },
        } = await handleCallBackendApi({
            query: manageAuth,
            variables: {
                values: JSON.stringify({
                    process: ManageAuthTypes.SEND_OTP,
                    userInfo: reqBody,
                }),
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        responseAuth = JSON.parse(responseAuth);
        responseAuth.body = JSON.parse(responseAuth?.body);
        return responseAuth;
    } catch (e) {
        return {
            statusCode: 500,
            body: {
                result: false,
            }
        }
    }
}
export const handleVerifyOTP = async (reqBody) => {
    try {
        let {
            data: { manageAuth: responseAuth },
        } = await handleCallBackendApi({
            query: manageAuth,
            variables: {
                values: JSON.stringify({
                    process: ManageAuthTypes.VERIFY_OTP,
                    userInfo: reqBody,
                }),
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        responseAuth = JSON.parse(responseAuth);
        responseAuth.body = JSON.parse(responseAuth?.body);
        return responseAuth;
    } catch (e) {
        return {
            statusCode: 500,
            body: {
                result: false,
            }
        }
    }
}

export const handleGetInviteUserByCode = async (code) => {
    try {
        let {
            data: { byReferralCode: { items: userRefferal } },
        } = await handleCallBackendApi({
            query: byReferralCodeInfo,
            variables: {
                code,
                filter: {
                    isUsed: { eq: false },
                }
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        return userRefferal.length > 0 ? userRefferal[0] : false
    } catch (e) {
        //console.log("e", e)
        return false
    }
}
export const handle = async (code) => {
    try {
        let {
            data: { byReferralCode: { items: userRefferal } },
        } = await handleCallBackendApi({
            query: byReferralCodeInfo,
            variables: {
                code
            },
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        return userRefferal.length > 0 ? userRefferal[0] : false
    } catch (e) {
        //console.log("e", e)
        return false
    }
}
export const loadImageKey = async (imageToLoad) => {
    try {
        return await Storage.get(imageToLoad, {
            level: "public",
            contentType: 'image/jpeg'
        });
    } catch (e) {
        //console.log("error img", e);
        return null;
    }
};
export async function getSignedUrl(reqBody) {
    try {
        // return await Storage.get(imageToLoad, {
        //     contentType: 'application/octet-stream'
        // });
        let { data: { invokeLambdaApi: urlSigned } } = await API.graphql({
            query: invokeLambdaApi,
            variables: {
                values: JSON.stringify({
                    requestType: "GET_SIGNED_URL",
                    reqBody: reqBody
                })
            },
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM
        })
        return JSON.parse(urlSigned)


    } catch (e) {
        //console.log("error img", e);
        return "";
    }
}

export const handleGetDeviceIpAdress = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const { ip } = await response.json()
        return ip || null
    } catch (e) {
        return null
    }
}