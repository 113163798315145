import React from "react";
import _style from "./css/style.module.css";
// import { DefaultComponentProps } from "../../interfaces/Component";
// type ColProps = {
//   width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
//   flex?: number;
// };
// type FlexProps = {
//   children: any;
//   padding?: string;
// } & DefaultComponentProps;
// type GridProps = {
//   width?: string;
//   columns?: number;
// };

// type FloatProps = {
//   float?: "left" | "right" | "none";
// };
const threatChildren = (children) => {
  if (typeof children !== "function" || typeof children !== "object") {
    return children;
  }

  return React.Children.map(children, (child, index) => {
    // Clonar el elemento hijo y agregar la clase adicional
    return React.cloneElement(child, {
      className: `${child?.props?.className} ${_style.item}`,
      key: index,
    });
  });
};

export function Column({
  children,
  className,
  style,
  padding,
  width,
  flex,
}//: FlexProps & ColProps
) {
  return (
    <div
      className={`${_style.column} ${_style[`col-${width || "default"}`]
        } ${className}`}
      style={{ flex, margin: padding, ...style }}
    >
      {threatChildren(children)}
    </div>
  );
}

export function Row({ children, className, style, padding }//: FlexProps
) {
  return (
    <div className={`${className} ${_style.row}`} style={{ padding, ...style }}>
      {threatChildren(children)}
    </div>
  );
}

export function Grid({ children, className, style }//: FlexProps & GridProps
) {
  return (
    <div className={`${className} ${_style.grid}`} style={{ ...style }}>
      {threatChildren(children)}
    </div>
  );
}
export function Float({
  children,
  className,
  style,
  float,
}//: FlexProps & FloatProps
) {
  const s//: CSSProperties 
    = {
    display: "flex",
    flexWrap: "wrap",
  };
  return (
    <div className={`${className} ${_style.float}`} style={{ ...style, ...s }}>
      {threatChildren(children)}
    </div>
  );
}

export function Container({ children, className, style }//: FlexProps
) {
  return (
    <div className={`${className} ${_style.container}`} style={style}>
      {threatChildren(children)}
    </div>
  );
}
