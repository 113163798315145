import React, { useEffect, useState } from "react";
import './PhoneInputStyle.css';
import { countryImages } from "./assets/images";
import esCountries from './lang/esTemp.json'
import { Avatar, Input, Select, SelectItem, Spacer } from "@nextui-org/react";
import { Container, Row } from "../flex/flex";
import { I18n } from "aws-amplify";
import StyledText from "../text/Text";
import AnimatedContainer from "../AnimatedContainer/animated-container";
import { YUP_REGEX } from "../formik/validation/custom";
export default function PhoneInput(props) {
    const [countryAlphaCode, setCountryAlphaCode] = useState(props?.countryAlphaCode || '')
    const [startContent, setStartContent] = useState({})
    const [countryCode, setCountryCode] = useState(props?.countryCode || '')
    useEffect(() => {
        setStartContent({
            startContent: <div className={`flag`} />
        })
        if (countryAlphaCode) {
            setTimeout(() => {
                setStartContent({
                    startContent: < AnimatedContainer animationTo={'top'} hiddenY={10} duration={0.2}>
                        <div className={`flag ${countryAlphaCode}`}
                        />
                    </AnimatedContainer>
                })
            }, 200)
        }
        if (props?.onValidatePhone) props?.onValidatePhone(countryAlphaCode && YUP_REGEX.ONLY_NUMBERS.test(countryCode) && YUP_REGEX.PHONE_NUMBER.test(props?.value));

    }, [countryAlphaCode])
    const handleOnChangeCode = async (e) => {
        const { value } = e.target;
        setCountryCode(value)
        const countryItem = esCountries.countries.find(item => item.countryCode == value)
        if (props?.onValidatePhone) await props?.onValidatePhone(countryItem?.key && YUP_REGEX.ONLY_NUMBERS.test(value) && YUP_REGEX.PHONE_NUMBER.test(props?.value));
        setCountryAlphaCode(countryItem?.key || '')
        if (props?.onChangeCountryCode) await props?.onChangeCountryCode(value)
        if (props?.onChangeCountryAlphaCode) await props?.onChangeCountryAlphaCode(countryItem?.key || '')
        if (props?.onChangeFormattedValue) await props?.onChangeFormattedValue(`+${value}${props?.value}`)

    }
    return <Container className="react-tel-input" >
        <Select
            {...startContent}
            selectionMode="single"
            items={esCountries.countries}
            label={I18n.get('COUNTRY_LABEL')}
            onSelectionChange={async (value) => {
                const countryItem = esCountries.countries.find(item => item.key == value?.currentKey)
                if (countryItem) {
                    setCountryCode(countryItem.countryCode)
                }
                if (props?.onValidatePhone) await props?.onValidatePhone(countryItem && YUP_REGEX.ONLY_NUMBERS.test(countryItem.countryCode) && YUP_REGEX.PHONE_NUMBER.test(props?.value));

                setCountryAlphaCode(value?.currentKey)
                if (props?.onChangeCountryCode) await props?.onChangeCountryCode(countryItem.countryCode)
                if (props?.onChangeCountryAlphaCode) await props?.onChangeCountryAlphaCode(value?.currentKey)
                if (props?.onChangeFormattedValue) await props?.onChangeFormattedValue(`+${countryItem.countryCode}${props?.value}`)

            }}
            selectedKeys={[countryAlphaCode]}
        >
            {(item) => <SelectItem
                key={item.key}
            >{item.label}</SelectItem>}
        </Select >
        <Spacer y={5} />
        <Input
            {...props}
            classNames={{
                inputWrapper: 'inputWrapper-main-phone',
                input: 'input-main-phone'
            }}
            onChange={(e) => {
                const { value } = e?.target;
                if (props?.onValidatePhone) props?.onValidatePhone(countryAlphaCode && YUP_REGEX.PHONE_NUMBER.test(value) && YUP_REGEX.ONLY_NUMBERS.test(countryCode));
                if (props?.onChange) props?.onChange(e)
                if (props?.onChangeFormattedValue) props?.onChangeFormattedValue(`+${countryCode}${value}`)
            }}
            startContent={
                <div className="code-mian-wrapper">
                    <StyledText style={{ marginRight: '5px' }} >+</StyledText>
                    <Input
                        fullWidth={false}
                        variant="bordered"
                        classNames={{
                            inputWrapper: 'input-country-code'
                        }}
                        maxLength={4}
                        value={countryCode}
                        onChange={handleOnChangeCode}
                    />
                </div>
            }
        />
    </Container >
}