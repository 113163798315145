/* eslint-disable react/prop-types */
export const es_ES = {
	SIGNUP_TEXT: 'REGÍSTRATE',
	HOME_TEXT: 'INICIO',
	WHY_TEXT: '¿POR QUÉ MONEYBLINKS?',
	FAQ_TEXT: 'PREGUNTAS FRECUENTES',
	TUTORIAL_TEXT: 'TUTORIALES',
	US_TEXT: 'NOSOTROS',
	CONTACT_TEXT: 'CONTACTO',
	CREATE_ACCOUNT_TEXT: 'Crea tu cuenta Moneyblinks',
	CREATE_ACCOUNT_SUBTEXT: 'Envía y recibe dinero de manera fácil y segura.',
	SIGNIN_WITH_GOOGLE: 'Iniciar con Google',
	REFERRAL_CODE_TEXT: 'Código de referido',
	EMAIL_TEXT: 'Correo electrónico',
	PLCAE_HOLDER_EMAIL_TEXT: 'ejemplo25@dominio.com',
	CREATE_ACCOUNT_TEXT_BTN: 'Empezar',
	TERMS_AND_CONDITIONS: 'He leído y acepto los ',
	TERMS_AND_CONDITIONS_TEXT: 'Términos y Condiciones.',
	USER_IS_INVITTIN_YOU: ' te invita a enviar dinero de manera fácil y rápida con Moneyblinks.',
	NOT_USER_IS_INVITTIN_YOU: 'Somos la aplicación que te facilita los envíos.',
	JOIN_TO_OUR_COMUNITY: '¡Realiza tus envíos de dinero sin complicaciones y en cuestión de minutos!',
	VERIFICATION_CODE: 'Código de verificación',
	VERIFICATE_BTN: 'Verificar',
	CONTINUE_BTN: 'Continuar',
	CREATE_ACCOUNT_BTN: 'Crear cuenta',
	PHONE_NUMBER_TEXT: "Número de teléfono",
	USERNAME_TEXT: "Usuario",
	FIRST_NAME_TEXT: "Primer nombre",
	SECOND_NAME_TEXT: "Segundo nombre",
	PASSWORD_TEXT: "Contraseña",
	CONFIRM_PASSWORD_TEXT: "Confirmar contraseña",
	ACCEPT_USER: "Acepto",
	CANCEL: "Cancelar",
	USERNAME_INVALID: 'El nombre de usuario no es válido. Debe tener una longitud entre 4 y 75 caracteres, debe iniciar con al menos 4 letras y sólo debe tener letras minúsculas, números o ".".',
	USERNAME_IS_REQUIRED: "El nombre de usuario es obligatorio.",
	PASSWORD_INVALID: 'La contraseña no es válida. Debe tener una longitud entre 8 y 16 caracteres. Al menos una letra mayúscula, una letra minúscula, un número y un símbolo "!@#$%^&*".',
	PASSWORD_IS_REQUIRED: "La contraseña es obligatoria.",
	PASSWORD_DOES_NOT_MATCH: "La contraseña de confirmación no coincide.",
	PASSWORD_CONFIRMATION_IS_REQUIRED: "La contraseña de confirmación es obligatoria.",
	EMAIL_INVALID: "El correo electrónico no es válido.",
	EMAIL_IS_REQUIRED: "El correo electrónico es obligatorio.",
	REQUIRED_PHONE: "El teléfono es obligatorio.",
	PHONE_INVALID: "El número de teléfono no es válido.",
	NOT_ESPECIAL_CHARS: "No se acepta caracteres especiales.",
	USERNAME_IS_REQUIRED: "El nombre de usuario es obligatorio.",
	LAST_NAME_IS_REQUIRED: "El apellido es obligatorio.",
	VERIFICATION_CODE_REQUIRED: "El código de verificación es obligatorio.",
	VERIFICATION_CODE_INVALID: "El código de verificación se conforma solo de números.",
	ENTER_USER_EMAILCODE_INFO: "Por favor, ingresa el código que se ha enviado a tu correo electrónico.",
	ENTER_USER_PHONE_CODE_INFO: "Por favor, ingresa el código que se ha enviado a tu número de teléfono.",
	COUNTRY_LABEL: "País",
	NAME_IS_REQUIRED: "El nombre es obligatorio.",
	INVALID_PHONE: "El número de teléfono no es válido.",
	NICKNAME_IN_USE: "El usuario está en uso.",
	EMAIL_IN_USE: "El correo electrónico está en uso.",
	PHONE_IN_USE: "El número de teléfono está en uso.",
	AN_ERROR_OCCURRED: "Ha ocurrido un error inesperado. Intenta más tarde.",
	WRONG_CONFIRMATION_CODE: "El código ingresado es incorrecto.",
	WRONG_INPUT_VALUES: "Los valores ingresados no cumplen con el esquema de validación de MoneyBlinks.",
	ACCEPT: "Aceptar",
	FINAL_TEXT_SIGNUP_USER: "Descarga nuestra app en la tienda de Google Play o Apple Store y empieza a envíar dinero de la manera más fácil.",
	FINAL_TEXT_SIGNUP_USER_HEADER: "¡Gracias por tu registro en MoneyBlinks!",
	USER_ALREADY_REGISTERED: "Usuario ya registrado.",
	SECONDS_TEXT: "segundos",
	MINUTE_TEXT: "minutos",
	HOURS_TEXT: "horas",
	WAIT_TEXT: "Por favor, espera ",
	RESEND_BTN: "Reenviar código",
	RESEND_OK: "El código ha sido enviado.",
	WAIT_TIME_RETRY_TEXT: "Has realizado muchos intentos, por favor espera unos momentos.",
	NAMES: "Nombres",
	LAST_NAMES:"Apellidos"













};