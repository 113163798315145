import { motion } from "framer-motion";

export default function AnimatedContainer({
    children,
    animationTo,
    hiddenX,
    hiddenY,
    duration
}) {
    let _animationVariants = {
        hidden: {
            x: 0,
            y: 0,
            opacity: 0,
        },
        visible: {
            x: 0,
            y: 0,
            opacity: 0,
        },
    }
    switch (animationTo) {
        case "left": {
            _animationVariants.hidden.x = 100;
            _animationVariants.visible.opacity = 1;
            if (hiddenX) _animationVariants.hidden.y = hiddenX;
            break;
        }
        case "top": {
            _animationVariants.hidden.y = 50;
            _animationVariants.visible.opacity = 0.7;
            if (hiddenY) _animationVariants.hidden.y = hiddenY;
            break;
        }
        case "rigth": {
            _animationVariants.hidden.x = -100;
            _animationVariants.visible.opacity = 1;
            if (hiddenX) _animationVariants.hidden.y = hiddenX;
            break;
        }
        case "bottom": {
            _animationVariants.hidden.y = -100;
            _animationVariants.visible.opacity = 0;
            if (hiddenY) _animationVariants.hidden.y = hiddenY;
            break;
        }
        default: {
            _animationVariants.hidden.y = 100;
            _animationVariants.visible.opacity = 0;
        }
    }

    return (<motion.div
        variants={_animationVariants}
        initial={animationTo ? 'hidden' : 'visible'}
        animate={(custom) => ((custom) ? "visible" : "hidden")}
        custom={true}
        transition={{ duration: duration || 0.8, ease: "easeInOut" }}
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
        {children}
    </motion.div>)
}