import React, { useEffect, useRef, useState } from "react";
import styles from "./css/signup.module.css";
import { Content } from "../../components/Content/Content";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  Checkbox,
  Divider,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spacer,
  avatar,
  useDisclosure,
} from "@nextui-org/react";
import { Auth, I18n } from "aws-amplify";
import StyledText from "../../components/text/Text";
import {
  AnimatedContainer,
  Column,
  Row,
  UserTransaction,
} from "../../constants/export-custom";
import * as Yup from "yup";
import { IoMdArrowRoundBack, IoIosEye, IoIosEyeOff } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import {
  BACKEND_ERRORS,
  MEDIA_BUTTONS,
  NotificationChannels,
  StepsSignUp,
} from "../../constants/constants";
import { TermsPage } from "../Terms/TermsPage";
import {
  getSignedUrl,
  handleCallBackendApi,
  handleGetDeviceIpAdress,
  handleGetInviteUserByCode,
  handleGetURLQueryParams,
  handleSendVerificationOTP,
  handleVerifyOTP,
  loadImageKey,
} from "../../utils/Utils";
import { YUP_REGEX } from "../../components/formik/validation/custom";
import { THEME_COLORS } from "../../components/text/config/Theme";
import PhoneInput from "../../components/PhoneInput/PhoneInput";
import { byEmail, byNickname, byPhoneNumber } from "../../graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Skeleton, message } from "antd";
import { createMBUser, updateMBReferralCode } from "../../graphql/mutations";
import { useTimer } from "../../hooks/UseTimer";
import { ErrorMessage, Field, Form, Formik } from "formik/dist";

export const SignUp = () => {
  const navigation = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [signUpStep, setSignUpStep] = useState(StepsSignUp.EMAIL);
  const [isLoadingbButton, setIsLoadingbButton] = useState(false);
  const [isDisablesButton, setIsDisablesButton] = useState(false);

  const [finalModal, setMinalModal] = useState(false);
  const [userReferral, setUserReferral] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const formRef = useRef(null);
  const { handleResetTimer, remainingTime, canRetry } = useTimer({
    sizeTimer: 0,
  });
  const {
    handleResetTimer: handleResetResendTimer,
    remainingTime: remainingResendTime,
    canRetry: canRetryResend,
  } = useTimer({
    sizeTimer: 0,
  });
  const [mediaButtons] = useState(MEDIA_BUTTONS);
  useEffect(() => {
    const query = handleGetURLQueryParams("invite_code");
    if (query) {
      formRef?.current?.setFieldValue("referralCode", query);
      handleGetInviteUserByCode(query).then((userReferral) => {
        if (userReferral)
          getSignedUrl({
            key: `public/${userReferral?.user?.avatarUrl}`,
          }).then((imgUrl) => {
            setAvatarUrl(imgUrl);
          });
        setUserReferral(userReferral);
      });
    } else {
      setUserReferral(false);
    }
  }, []);
  const handleOnBack = () => {
    switch (signUpStep) {
      case StepsSignUp.VERIFICATION_EMAIL_CODE: {
        setSignUpStep(StepsSignUp.EMAIL);
        break;
      }
      case StepsSignUp.PHONE: {
        setSignUpStep(StepsSignUp.EMAIL);
        break;
      }
      case StepsSignUp.VERIFICATION_PHONE_CODE: {
        setSignUpStep(StepsSignUp.PHONE);
        break;
      }
      case StepsSignUp.FINAL_DATA: {
        setSignUpStep(StepsSignUp.PHONE);
        break;
      }
    }
  };
  const handleResendCode = async () => {
    setIsLoadingbButton(true);
    setIsDisablesButton(true);
    try {
      switch (signUpStep) {
        case StepsSignUp.VERIFICATION_EMAIL_CODE: {
          const { statusCode, body } = await handleSendVerificationOTP({
            channel: NotificationChannels.EMAIL,
            phoneEmail: formRef?.current?.values?.email,
            ipAdress:
              (await handleGetDeviceIpAdress()) ||
              formRef?.current?.values?.email,
          });
          if (statusCode == 200 && body?.result) {
            messageApi.open({
              type: "success",
              content: I18n.get("RESEND_OK"),
            });
          } else if (
            statusCode == 406 &&
            body?.statusMessage == BACKEND_ERRORS.ATTEMPTS_EXCEEDED
          ) {
            messageApi.open({
              type: "warning",
              content: I18n.get("WAIT_TIME_RETRY_TEXT"),
            });
            handleResetResendTimer(body?.secondsWAit || 0);
          } else {
            messageApi.open({
              type: "error",
              content: I18n.get("AN_ERROR_OCCURRED"),
            });
          }

          break;
        }
        case StepsSignUp.VERIFICATION_PHONE_CODE: {
          const { statusCode, body } = await handleSendVerificationOTP({
            channel: NotificationChannels.SMS,
            phoneEmail: formRef?.current?.values?.formattedPhoneNumber,
            ipAdress:
              (await handleGetDeviceIpAdress()) ||
              formRef?.current?.values?.formattedPhoneNumber,
          });
          if (statusCode == 200 && body?.result) {
            messageApi.open({
              type: "success",
              content: I18n.get("RESEND_OK"),
            });
          } else if (
            statusCode == 406 &&
            body?.statusMessage == BACKEND_ERRORS.ATTEMPTS_EXCEEDED
          ) {
            messageApi.open({
              type: "warning",
              content: I18n.get("WAIT_TIME_RETRY_TEXT"),
            });
            handleResetResendTimer(body?.secondsWAit || 0);
          } else {
            messageApi.open({
              type: "error",
              content: I18n.get("AN_ERROR_OCCURRED"),
            });
          }
          break;
        }
      }
    } catch (e) {
      //console.log("errors", e)
    }
    setIsDisablesButton(false);
    setIsLoadingbButton(false);
  };
  const handleOnSubmit = async () => {
    if (!formRef?.current) {
      return;
    }
    await formRef?.current?.validateForm(formRef?.current?.values);
    setIsLoadingbButton(true);
    try {
      switch (signUpStep) {
        case StepsSignUp.EMAIL: {
          if (formRef?.current?.errors?.email) {
            formRef?.current?.setFieldTouched("email");
          } else {
            const { email } = await onValidate(formRef?.current?.values, true);
            if (!email) {
              const { statusCode, body } = await handleSendVerificationOTP({
                channel: NotificationChannels.EMAIL,
                phoneEmail: formRef?.current?.values?.email,
                ipAdress:
                  (await handleGetDeviceIpAdress()) ||
                  formRef?.current?.values?.email,
              });
              if (statusCode == 200 && body?.result) {
                setSignUpStep(StepsSignUp.VERIFICATION_EMAIL_CODE);
              } else if (
                statusCode == 406 &&
                body?.statusMessage == BACKEND_ERRORS.ATTEMPTS_EXCEEDED
              ) {
                messageApi.open({
                  type: "warning",
                  content: I18n.get("WAIT_TIME_RETRY_TEXT"),
                });
                handleResetTimer(body?.secondsWAit || 0);
              } else {
                formRef?.current?.setFieldError(
                  "email",
                  body?.statusMessage || "AN_ERROR_OCCURRED"
                );
              }
            } else {
              formRef?.current.setFieldError("email", email);
            }
          }
          break;
        }
        case StepsSignUp.VERIFICATION_EMAIL_CODE: {
          if (formRef?.current?.errors?.emailCode) {
            formRef?.current?.setFieldTouched("emailCode");
          } else {
            const { statusCode, body } = await handleVerifyOTP({
              verificationCode: formRef?.current?.values?.emailCode,
              channel: NotificationChannels.EMAIL,
              phoneEmail: formRef?.current?.values?.email,
              ipAdress:
                (await handleGetDeviceIpAdress()) ||
                formRef?.current?.values?.email,
            });
            if (statusCode == 200 && body?.result) {
              setSignUpStep(StepsSignUp.PHONE);
            } else if (
              statusCode == 406 &&
              body?.statusMessage == BACKEND_ERRORS.ATTEMPTS_EXCEEDED
            ) {
              messageApi.open({
                type: "warning",
                content: I18n.get("WAIT_TIME_RETRY_TEXT"),
              });
              handleResetTimer(body?.secondsWAit || 0);
            } else {
              formRef?.current?.setFieldError(
                "emailCode",
                body?.statusMessage || "AN_ERROR_OCCURRED"
              );
            }
          }
          break;
        }
        case StepsSignUp.PHONE: {
          if (
            formRef?.current?.errors?.formattedPhoneNumber ||
            !formRef?.current?.values?.phoneNumber ||
            !formRef?.current?.values?.countryCode
          ) {
            formRef?.current?.setFieldTouched("formattedPhoneNumber");
            formRef?.current?.setFieldTouched("countryCode");
          } else {
            const { formattedPhoneNumber } = await onValidate(
              formRef?.current?.values,
              true
            );
            if (!formattedPhoneNumber) {
              const { statusCode, body } = await handleSendVerificationOTP({
                channel: NotificationChannels.SMS,
                phoneEmail: formRef?.current?.values?.formattedPhoneNumber,
                ipAdress:
                  (await handleGetDeviceIpAdress()) ||
                  formRef?.current?.values?.formattedPhoneNumber,
              });
              if (statusCode == 200 && body?.result) {
                setSignUpStep(StepsSignUp.VERIFICATION_PHONE_CODE);
              } else if (
                statusCode == 406 &&
                body?.statusMessage == BACKEND_ERRORS.ATTEMPTS_EXCEEDED
              ) {
                messageApi.open({
                  type: "warning",
                  content: I18n.get("WAIT_TIME_RETRY_TEXT"),
                });
                handleResetTimer(body?.secondsWAit || 0);
              } else {
                formRef?.current?.setFieldError(
                  "formattedPhoneNumber",
                  body?.statusMessage || "AN_ERROR_OCCURRED"
                );
              }
            } else {
              formRef?.current.setFieldError(
                "formattedPhoneNumber",
                formattedPhoneNumber
              );
            }
          }
          break;
        }
        case StepsSignUp.VERIFICATION_PHONE_CODE: {
          if (formRef?.current?.errors?.phoneCode) {
            formRef?.current?.setFieldTouched("phoneCode");
          } else {
            const { statusCode, body } = await handleVerifyOTP({
              verificationCode: formRef?.current?.values?.phoneCode,
              channel: NotificationChannels.SMS,
              phoneEmail: formRef?.current?.values?.formattedPhoneNumber,
              ipAdress:
                (await handleGetDeviceIpAdress()) ||
                formRef?.current?.values?.formattedPhoneNumber,
            });
            if (statusCode == 200 && body?.result) {
              setSignUpStep(StepsSignUp.FINAL_DATA);
            } else if (
              statusCode == 406 &&
              body?.statusMessage == BACKEND_ERRORS.ATTEMPTS_EXCEEDED
            ) {
              messageApi.open({
                type: "warning",
                content: I18n.get("WAIT_TIME_RETRY_TEXT"),
              });
              handleResetTimer(body?.secondsWAit || 0);
            } else {
              formRef?.current?.setFieldError(
                "phoneCode",
                body?.statusMessage || "AN_ERROR_OCCURRED"
              );
            }
          }
          break;
        }
        case StepsSignUp.FINAL_DATA: {
          const _validated = await formRef?.current?.validateForm(
            formRef?.current?.values
          );
          if (Object.keys(_validated).length > 0) {
            for (const key of Object.keys(_validated)) {
              formRef?.current?.setFieldTouched(key);
            }
          } else {
            formRef?.current?.submitForm();
            return;
          }
        }
      }
    } catch (e) {
      //console.log("errors", e)
    }
    setIsLoadingbButton(false);
  };
  const handleSignUpUser = async (values) => {
    try {
      const {
        data: {
          byNickname: { items: existUser },
        },
      } = await handleCallBackendApi({
        query: byNickname,
        variables: {
          nickname: values.username,
          filter: {
            or: [{ isDeleted: { eq: false } }, { isDeleted: { ne: true } }],
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      if (existUser.length > 0) {
        messageApi.open({
          type: "error",
          content: I18n.get("USER_ALREADY_REGISTERED"),
        });
        return;
      }
      const {
        data: { createMBUser: mbUser },
      } = await handleCallBackendApi({
        query: createMBUser,
        variables: {
          input: {
            cognitoUserId: "null",
            type: "USER",
            nickname: values?.username?.toLowerCase(),
            fullName: `${values.firstName} ${values.lastName}`,
            email: values.email.toLowerCase(),
            phoneNumber: values?.formattedPhoneNumber,
            isAvailabilityTx: false,
            locale: "es",
            checkEmail: false,
            checkPhone: false,
            currency: "USD",
            isTerms: true,
            alpha3Code: "USA",
            alpha2Code: "NJ",
            city: "",
            zipCode: "",
            state: "",
            country: "",
            isUsedMoneyBlinkAmount: false,
            role: "mbuser",
          },
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY,
      });
      await Auth.signUp({
        username: values?.username,
        password: values?.password,
        attributes: {
          email: values?.email,
          locale: "es",
          name: `${values?.firstName} ${values?.lastName}`,
          nickname: values?.username?.toLowerCase(),
          phone_number: values?.formattedPhoneNumber,
          family_name: values?.firstName,
          given_name: values?.lastName,
        },
      });
      try {
        if (userReferral && values?.referralCode) {
          await handleCallBackendApi({
            query: updateMBReferralCode,
            variables: {
              input: {
                id: userReferral?.id,
                usedBy: mbUser?.id,
                isUsed: true,
                usedAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
          });
        }
      } catch (e) {
        //console.log("error al actualizar", e)
      }
      setMinalModal(true);
    } catch (e) {
      messageApi.open({
        type: "error",
        content: I18n.get("AN_ERROR_OCCURRED"),
      });
      //console.log("error", e)
    } finally {
      setIsLoadingbButton(false);
    }
  };
  async function onValidate(values, canValidate) {
    let errors = {};
    try {
      if (values?.username) {
        const {
          data: {
            byNickname: { items: existUser },
          },
        } = await handleCallBackendApi({
          query: byNickname,
          variables: {
            nickname: values.username,
            filter: {
              or: [{ isDeleted: { eq: false } }, { isDeleted: { ne: true } }],
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        if (existUser.length > 0) {
          errors["username"] = "NICKNAME_IN_USE";
        }
      }
      if (values?.email) {
        const {
          data: {
            byEmail: { items: existEmail },
          },
        } = await handleCallBackendApi({
          query: byEmail,
          variables: {
            email: values?.email?.toLowerCase(),
            filter: {
              or: [{ isDeleted: { eq: false } }, { isDeleted: { ne: true } }],
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        if (existEmail.length > 0) {
          errors["email"] = "EMAIL_IN_USE";
        }
      }
      if (
        canValidate &&
        values?.isValidPhoneNumber &&
        values?.formattedPhoneNumber
      ) {
        const {
          data: {
            byPhoneNumber: { items: existPhone },
          },
        } = await handleCallBackendApi({
          query: byPhoneNumber,
          variables: {
            phoneNumber: values?.formattedPhoneNumber,
            filter: {
              or: [{ isDeleted: { eq: false } }, { isDeleted: { ne: true } }],
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const {
          data: {
            byPhoneNumber: { items: existFormattedPhone },
          },
        } = await handleCallBackendApi({
          query: byPhoneNumber,
          variables: {
            phoneNumber: `+${values?.countryCode}${values?.phoneNumber.slice(
              1
            )}`,
            filter: {
              or: [{ isDeleted: { eq: false } }, { isDeleted: { ne: true } }],
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
        const {
          data: {
            byPhoneNumber: { items: existFormattedPhoneV2 },
          },
        } = await handleCallBackendApi({
          query: byPhoneNumber,
          variables: {
            phoneNumber: `+${values?.countryCode}0${values?.phoneNumber}`,
            filter: {
              or: [{ isDeleted: { eq: false } }, { isDeleted: { ne: true } }],
            },
          },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });
		console.log("existPhone",existPhone)
		console.log("existFormattedPhone",existFormattedPhone)

		console.log("existFormattedPhoneV2",existFormattedPhoneV2)

        if (
          existPhone.length > 0 ||
          existFormattedPhone.length > 0 ||
          existFormattedPhoneV2.length > 0
        ) {
          errors["formattedPhoneNumber"] = "PHONE_IN_USE";
        }
      } else if (canValidate && !values?.isValidPhoneNumber) {
        errors["formattedPhoneNumber"] = "INVALID_PHONE";
      }
      //console.log("errors", errors)
      return errors;
    } catch (e) {
      return {
        formattedPhoneNumber: "INVALID_PHONE",
        username: "USERNAME_INVALID",
        email: "EMAIL_INVALID",
      };
    }
  }
  return (
    <div className={styles.mainContainer}>
      {contextHolder}
      <Content>
        <Card className={styles.cardContainer}>
          <CardBody className={styles.mainCar}>
            <div className={styles.cardLeftSignUp}>
              <Image
                isZoomed
                onClick={() => {
                  navigation("/");
                  window.location.reload();
                }}
                removeWrapper
                className={styles.appIcon}
                src="/assets/images/iconmb.png"
              />

              <StyledText
                className={styles.cardInfoTitle}
                title
                style={{ fontFamily: "Raleway", fontWeight: "500" }}
              >
                {I18n.get("CREATE_ACCOUNT_TEXT")}
              </StyledText>
              <StyledText
                className={styles.cardInfoSubTitle}
                size={"medium"}
                gray
              >
                {I18n.get("CREATE_ACCOUNT_SUBTEXT")}
              </StyledText>
              <Spacer y={5} />

              {/* <Button color='black' variant='faded' startContent={
							<Image
								radius='full'
								width={20}
								src={GoogleIcon}
							/>
						}>
							{I18n.get('SIGNIN_WITH_GOOGLE')}
						</Button> */}
              {signUpStep != StepsSignUp.EMAIL && (
                <AnimatedContainer animationTo={"top"}>
                  <Button
                    isIconOnly
                    onClick={handleOnBack}
                    color="primary"
                    variant="flat"
                    startContent={<IoMdArrowRoundBack size={20} />}
                  />
                </AnimatedContainer>
              )}
              <Spacer y={2} />
              <div className={styles.formContainer}>
                <Formik
                  onSubmit={handleSignUpUser}
                  initialValues={{
                    referralCode: "",
                    email: "",
                    username: "",
                    password: "",
                    pw2: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    showEmailCodeInput: false,
                    emailCode: "",
                    phoneCode: "",
                    isVisiblePassword: false,
                    isVisibleConfirmPassword: false,
                    isTerms: false,
                    countryCode: "",
                    countryAlphaCode: "",
                    formattedPhoneNumber: "",
                    isValidPhoneNumber: false,
                  }}
                  validate={onValidate}
                  validateOnChange={true}
                  validationSchema={Yup.object({
                    formattedPhoneNumber: Yup.string()
                      .nullable()
                      .required("REQUIRED_PHONE"),
                    emailCode: Yup.string()
                      .nullable()
                      .matches(
                        YUP_REGEX.ONLY_NUMBERS,
                        "VERIFICATION_CODE_INVALID"
                      )
                      .required("VERIFICATION_CODE_REQUIRED"),
                    phoneCode: Yup.string()
                      .nullable()
                      .matches(
                        YUP_REGEX.ONLY_NUMBERS,
                        "VERIFICATION_CODE_INVALID"
                      )
                      .required("VERIFICATION_CODE_REQUIRED"),
                    username: Yup.string()
                      .nullable()
                      .matches(YUP_REGEX.USERNAME, "USERNAME_INVALID")
                      .min(4, "USERNAME_INVALID")
                      .required("USERNAME_IS_REQUIRED"),
                    password: Yup.string()
                      .nullable()
                      .matches(YUP_REGEX.PASSWORD.MEDIUM, "PASSWORD_INVALID")
                      .min(8, "PASSWORD_INVALID")
                      .required("PASSWORD_IS_REQUIRED"),
                    pw2: Yup.string()
                      .oneOf(
                        [Yup.ref("password"), null],
                        "PASSWORD_DOES_NOT_MATCH"
                      )
                      .nullable()
                      .required("PASSWORD_CONFIRMATION_IS_REQUIRED"),
                    email: Yup.string()
                      .nullable()
                      .matches(YUP_REGEX.EMAIL.STRONG, "EMAIL_INVALID")
                      .required("EMAIL_IS_REQUIRED"),
                    firstName: Yup.string()
                      .nullable()
                      .matches(
                        YUP_REGEX.ESPECIAL_CHARS_NAME,
                        "NOT_ESPECIAL_CHARS"
                      )
                      .required("NAME_IS_REQUIRED"),
                    lastName: Yup.string()
                      .matches(
                        YUP_REGEX.ESPECIAL_CHARS_NAME,
                        "NOT_ESPECIAL_CHARS"
                      )
                      .nullable()
                      .required("LAST_NAME_IS_REQUIRED"),
                    isTerms: Yup.boolean()
                      .required("Terms and conditions must be accepted.")
                      .oneOf([true], "Terms and conditions must be accepted."),
                  })}
                  innerRef={(el) => (formRef.current = el)}
                >
                  {({
                    errors,
                    onSubmit,
                    setFieldValue,
                    hasErrors,
                    touched,
                    handleChange,
                    setFieldTouched,
                    hastouched,
                    validateField,
                    validate,
                    validateForm,
                    setFieldError,
                    values,
                  }) => (
                    <Column width={50}>
                      {signUpStep == StepsSignUp.EMAIL && (
                        <AnimatedContainer animationTo={"left"}>
                          {values?.referralCode && userReferral && (
                            <Input
                              isReadOnly
                              name="referralCode"
                              label={I18n.get("REFERRAL_CODE_TEXT")}
                              value={values?.referralCode}
                            />
                          )}

                          <Spacer y={4} />
                          <Input
                            isInvalid={!!(errors?.email && touched?.email)}
                            errorMessage={
                              (touched?.email && I18n.get(errors?.email)) || ""
                            }
                            name="email"
                            label={I18n.get("EMAIL_TEXT")}
                            onChange={(e) => {
                              const { value, name } = e.target;
                              setFieldValue(name, value);
                              setFieldTouched("email");
                            }}
                            maxLength={50}
                            value={values?.email}
                          />
                          <Spacer y={1} />
                        </AnimatedContainer>
                      )}
                      {signUpStep == StepsSignUp.VERIFICATION_EMAIL_CODE && (
                        <AnimatedContainer animationTo={"left"}>
                          <Input
                            name="emailCode"
                            isInvalid={
                              !!(errors?.emailCode && touched?.emailCode)
                            }
                            errorMessage={
                              (touched?.emailCode &&
                                I18n.get(errors?.emailCode)) ||
                              ""
                            }
                            label={I18n.get("VERIFICATION_CODE")}
                            description={`${I18n.get(
                              "ENTER_USER_EMAILCODE_INFO"
                            )} ${values?.email}`}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("emailCode");
                            }}
                            maxLength={10}
                            value={values?.emailCode}
                          />
                        </AnimatedContainer>
                      )}
                      {signUpStep == StepsSignUp.PHONE && (
                        <AnimatedContainer animationTo={"left"}>
                          <PhoneInput
                            isInvalid={
                              touched?.formattedPhoneNumber &&
                              !!errors?.formattedPhoneNumber
                            }
                            errorMessage={
                              (touched?.formattedPhoneNumber &&
                                errors?.formattedPhoneNumber &&
                                I18n.get(errors?.formattedPhoneNumber)) ||
                              ""
                            }
                            name="phoneNumber"
                            label={I18n.get("PHONE_NUMBER_TEXT")}
                            onChange={async (e) => {
                              const { value } = e?.target;
                              setFieldValue("phoneNumber", value);
                            }}
                            value={values?.phoneNumber}
                            maxLength={15}
                            onValidatePhone={async (isValid) => {
                              setFieldValue("isValidPhoneNumber", isValid);
                            }}
                            countryCode={values?.countryCode}
                            onChangeCountryCode={async (value) => {
                              setFieldValue("countryCode", value);
                            }}
                            countryAlphaCode={values?.countryAlphaCode}
                            onChangeCountryAlphaCode={async (value) => {
                              setFieldValue("countryAlphaCode", value);
                            }}
                            onChangeFormattedValue={async (value) => {
                              setFieldValue("formattedPhoneNumber", value);
                              setFieldTouched("formattedPhoneNumber");
                            }}
                          />
                        </AnimatedContainer>
                      )}
                      {signUpStep == StepsSignUp.VERIFICATION_PHONE_CODE && (
                        <AnimatedContainer animationTo={"left"}>
                          <Input
                            isInvalid={
                              !!(errors?.phoneCode && touched?.phoneCode)
                            }
                            errorMessage={
                              (touched?.phoneCode &&
                                I18n.get(errors?.phoneCode)) ||
                              ""
                            }
                            name="phoneCode"
                            label={I18n.get("VERIFICATION_CODE")}
                            description={`${I18n.get(
                              "ENTER_USER_PHONE_CODE_INFO"
                            )} ${values?.formattedPhoneNumber}`}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("phoneCode");
                            }}
                            maxLength={10}
                            value={values?.phoneCode}
                          />
                        </AnimatedContainer>
                      )}
                      {signUpStep == StepsSignUp.FINAL_DATA && (
                        <AnimatedContainer animationTo={"left"}>
                          <Input
                            isInvalid={
                              !!(errors?.username && touched?.username)
                            }
                            errorMessage={
                              (touched?.username &&
                                I18n.get(errors?.username)) ||
                              ""
                            }
                            name="username"
                            label={I18n.get("USERNAME_TEXT")}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("username");
                              setTimeout(() => validateField("username"), 1000);
                            }}
                            maxLength={20}
                            value={values?.username}
                          />
                          <Spacer y={4} />
                          <Input
                            isInvalid={
                              !!(errors?.firstName && touched?.firstName)
                            }
                            errorMessage={
                              (touched?.firstName &&
                                I18n.get(errors?.firstName)) ||
                              ""
                            }
                            name="firstName"
                            label={I18n.get("NAMES")}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("firstName");
                              setTimeout(
                                () => validateField("firstName"),
                                1000
                              );
                            }}
                            maxLength={50}
                            value={values?.firstName}
                          />
                          <Spacer y={4} />

                          <Input
                            isInvalid={
                              !!(errors?.lastName && touched?.lastName)
                            }
                            errorMessage={
                              (touched?.lastName &&
                                I18n.get(errors?.lastName)) ||
                              ""
                            }
                            name="lastName"
                            label={I18n.get("LAST_NAMES")}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("lastName");
                              setTimeout(() => validateField("lastName"), 1000);
                            }}
                            maxLength={50}
                            value={values?.lastName}
                          />
                          <Spacer y={4} />

                          <Input
                            isInvalid={
                              !!(errors?.password && touched?.password)
                            }
                            errorMessage={
                              (touched?.password &&
                                I18n.get(errors?.password)) ||
                              ""
                            }
                            name="password"
                            label={I18n.get("PASSWORD_TEXT")}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("password");
                              setTimeout(() => validateField("password"), 1000);
                              setTimeout(() => validateField("pw2"), 1000);
                            }}
                            value={values?.password}
                            endContent={
                              <button
                                className="focus:outline-none"
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    "isVisiblePassword",
                                    !values.isVisiblePassword
                                  )
                                }
                              >
                                {values.isVisiblePassword ? (
                                  <IoIosEyeOff size={20} />
                                ) : (
                                  <IoIosEye size={20} />
                                )}
                              </button>
                            }
                            maxLength={30}
                            type={
                              values.isVisiblePassword ? "text" : "password"
                            }
                          />
                          <Spacer y={4} />

                          <Input
                            isInvalid={!!(errors?.pw2 && touched?.pw2)}
                            errorMessage={
                              (touched?.pw2 && I18n.get(errors?.pw2)) || ""
                            }
                            name="pw2"
                            label={I18n.get("CONFIRM_PASSWORD_TEXT")}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("pw2");
                              setTimeout(() => validateField("pw2"), 1000);
                              setTimeout(() => validateField("password"), 1000);
                            }}
                            maxLength={30}
                            value={values?.pw2}
                            type={
                              values.isVisibleConfirmPassword
                                ? "text"
                                : "password"
                            }
                            endContent={
                              <button
                                className="focus:outline-none"
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    "isVisibleConfirmPassword",
                                    !values.isVisibleConfirmPassword
                                  )
                                }
                              >
                                {values.isVisibleConfirmPassword ? (
                                  <IoIosEyeOff size={20} />
                                ) : (
                                  <IoIosEye size={20} />
                                )}
                              </button>
                            }
                          />
                          <Spacer y={5} />
                          <Checkbox
                            defaultSelected
                            size="md"
                            color={"primary"}
                            isSelected={values?.isTerms}
                            onChange={() =>
                              setFieldValue("isTerms", !values?.isTerms)
                            }
                          >
                            <StyledText
                              size={"medium"}
                              gray
                              color={
                                errors?.isTerms && touched?.isTerms
                                  ? THEME_COLORS.ERROR
                                  : null
                              }
                            >
                              {I18n.get("TERMS_AND_CONDITIONS")}
                              <span
                                style={{ fontWeight: "bold" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onOpen();
                                }}
                              >
                                {I18n.get("TERMS_AND_CONDITIONS_TEXT")}
                              </span>
                            </StyledText>
                          </Checkbox>
                        </AnimatedContainer>
                      )}

                      <Spacer y={8} />
                      <Button
                        isLoading={!isDisablesButton && isLoadingbButton}
                        disabled={isDisablesButton}
                        color={"primary"}
                        variant="shadow"
                        isDisabled={!canRetry}
                        onClick={handleOnSubmit}
                      >
                        {canRetry && (
                          <>
                            {signUpStep == StepsSignUp.EMAIL && (
                              <StyledText size={"medium"} animationTo={"left"}>
                                {I18n.get("CREATE_ACCOUNT_TEXT_BTN")}
                              </StyledText>
                            )}
                            {signUpStep ==
                              StepsSignUp.VERIFICATION_EMAIL_CODE && (
                              <StyledText size={"medium"} animationTo={"left"}>
                                {I18n.get("VERIFICATE_BTN")}
                              </StyledText>
                            )}
                            {signUpStep == StepsSignUp.PHONE && (
                              <StyledText size={"medium"} animationTo={"left"}>
                                {I18n.get("CONTINUE_BTN")}
                              </StyledText>
                            )}
                            {signUpStep ==
                              StepsSignUp.VERIFICATION_PHONE_CODE && (
                              <StyledText size={"medium"} animationTo={"left"}>
                                {I18n.get("VERIFICATE_BTN")}
                              </StyledText>
                            )}
                            {signUpStep == StepsSignUp.FINAL_DATA && (
                              <StyledText size={"medium"} animationTo={"left"}>
                                {I18n.get("CREATE_ACCOUNT_BTN")}
                              </StyledText>
                            )}
                          </>
                        )}
                        {!canRetry && (
                          <StyledText size={"medium"} animationTo={"left"}>
                            {`${I18n.get("WAIT_TEXT")}${remainingTime}`}
                          </StyledText>
                        )}
                      </Button>
                      <Spacer y={2} />
                      {(signUpStep == StepsSignUp.VERIFICATION_EMAIL_CODE ||
                        signUpStep == StepsSignUp.VERIFICATION_PHONE_CODE) && (
                        <Button
                          isLoading={isDisablesButton && isLoadingbButton}
                          disabled={isLoadingbButton}
                          color={"primary"}
                          variant="shadow"
                          isDisabled={!canRetryResend}
                          onClick={handleResendCode}
                        >
                          {canRetryResend && (
                            <>
                              {
                                <StyledText
                                  size={"medium"}
                                  animationTo={"left"}
                                >
                                  {I18n.get("RESEND_BTN")}
                                </StyledText>
                              }
                            </>
                          )}
                          {!canRetryResend && (
                            <StyledText size={"medium"} animationTo={"left"}>
                              {`${I18n.get("WAIT_TEXT")}${remainingResendTime}`}
                            </StyledText>
                          )}
                        </Button>
                      )}
                    </Column>
                  )}
                </Formik>
              </div>
              <div className={styles.userReferralSignUp}>
                <Divider className={styles.divider} />
                <Spacer y={5} />
                {userReferral == null ? (
                  <Skeleton
                    loading={true}
                    active={true}
                    round={true}
                    avatar={true}
                    className="w-full flex flex-col gap-2"
                  />
                ) : (
                  <>
                    {userReferral && (
                      <Avatar
                        className={styles.avatarReferral}
                        style={{
                          width: "8vw",
                          height: "auto",
                          marginRight: "15px",
                          resize: "vertical",
                        }}
                        src={avatarUrl}
                        size="lg"
                      />
                    )}

                    <Spacer y={5} />
                    <StyledText
                      size={"medium"}
                      bold
                      style={{ textAlign: "center" }}
                    >
                      {userReferral
                        ? `${
                            userReferral?.user?.fullName?.split(" ")[0]
                          } ${I18n.get("USER_IS_INVITTIN_YOU")}`
                        : I18n.get("JOIN_TO_OUR_COMUNITY")}
                    </StyledText>
                  </>
                )}
              </div>

              <div className={styles.footerContainer}>
                <StyledText size={"medium"} gray>
                  © 2024 - Moneyblinks
                </StyledText>
                <Link to="/terms" reloadDocument>
                  <StyledText
                    className={styles.termsCondSignUp}
                    decoration={"underline"}
                    size={"medium"}
                    gray
                  >
                    {I18n.get("TERMS_AND_CONDITIONS_TEXT")}
                  </StyledText>
                </Link>
              </div>
            </div>
            <div className={styles.cardRigthSignUp}>
              <div className={styles.containerReferral}>
                <Spacer y={20} />
                {userReferral == null ? (
                  <Skeleton
                    loading={true}
                    active={true}
                    round={true}
                    avatar={true}
                    className="w-full flex flex-col gap-2"
                  />
                ) : (
                  <>
                    {userReferral && (
                      <Avatar
                        style={{ width: "8rem", height: "8rem" }}
                        src={avatarUrl || ""}
                        size="lg"
                      />
                    )}
                    <Spacer y={5} />
                    <StyledText size={"large"} bold>
                      {userReferral
                        ? `${
                            userReferral?.user?.fullName?.split(" ")[0]
                          } ${I18n.get("USER_IS_INVITTIN_YOU")}`
                        : I18n.get("NOT_USER_IS_INVITTIN_YOU")}
                    </StyledText>
                    <Spacer y={3} />
                    <StyledText size={"label"} grayDark>
                      {I18n.get("JOIN_TO_OUR_COMUNITY")}
                    </StyledText>
                  </>
                )}
              </div>
              <Spacer y={5} />
              <Lottie
                animationData={UserTransaction}
                loop={true}
                style={{
                  width: "60%",
                }}
              />

              <Spacer y={10} />
              <div className={styles.footerContainer}>
                <div />
                <Row>
                  {mediaButtons.map((media) => {
                    return (
                      <Image
                        key={media.link}
                        isZoomed
                        onClick={() => {
                          window.open(media.link, "_blank");
                        }}
                        width={35}
                        alt="NextUI hero Image with delay"
                        style={{ padding: "5px" }}
                        src={media.image}
                      />
                    );
                  })}
                </Row>
              </div>
            </div>
          </CardBody>
        </Card>
        <div>
          <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalBody
                    style={{ overflowX: "auto", padding: "0px", margin: "0px" }}
                  >
                    <TermsPage />
                  </ModalBody>
                  <ModalFooter className={styles.modalFooter}>
                    <Button color="danger" variant="light" onPress={onClose}>
                      {I18n.get("CANCEL")}
                    </Button>
                    <Button
                      color="primary"
                      onPress={() => {
                        onClose();
                        formRef?.current?.setFieldValue("isTerms", true);
                      }}
                    >
                      {I18n.get("ACCEPT_USER")}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        </div>
        <div>
          <Modal
            backdrop="blur"
            isOpen={finalModal}
            onOpenChange={(value) => {
              if (!value) window.location.reload();
            }}
            radius="lg"
          >
            <ModalContent>
              <ModalHeader className="flex flex-col gap-1">
                {I18n.get("FINAL_TEXT_SIGNUP_USER_HEADER")}
              </ModalHeader>

              <ModalBody>
                <p>{I18n.get("FINAL_TEXT_SIGNUP_USER")}</p>
              </ModalBody>
              <ModalFooter className={styles.modalFooter}>
                <Button
                  color="primary"
                  onPress={() => {
                    window.location.reload();
                  }}
                >
                  {I18n.get("ACCEPT")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </Content>
    </div>
  );
};
