export const newDesignLinks = ['signup'];
export const StepsSignUp = {
    EMAIL: "EMAIL",
    VERIFICATION_EMAIL_CODE: "VERIFICATION_EMAIL_CODE",
    PHONE: "PHONE",
    VERIFICATION_PHONE_CODE: "VERIFICATION_PHONE_CODE",
    FINAL_DATA: "FINAL_DATA",
}

export const ManageAuthTypes = {
    VERIFY_SIGNIN: "VERIFY_SIGNIN",
    VERIFY_PHONE_NUMBER: "VERIFY_PHONE_NUMBER",
    VERIFY_EMAIL: "VERIFY_EMAIL",
    SEND_TEMPORARY_PASSWORD: "SEND_TEMPORARY_PASSWORD",
    VERIFY_CHECK_DATA: "VERIFY_CHECK_DATA",
    LOGGIN_NUVEM: "LOGGIN_NUVEM",
    SEND_OTP: "SEND_OTP",
    VERIFY_OTP: "VERIFY_OTP"
}

export const NotificationChannels = {
    SMS: "SMS",
    EMAIL: "EMAIL",
}

export const BACKEND_ERRORS = {
    ATTEMPTS_EXCEEDED: "ATTEMPTS_EXCEEDED",
}

export const MEDIA_BUTTONS = [
    {
        link: 'https://www.instagram.com/moneyblinks.ec/',
        image: 'https://d3r4ipxr51o1re.cloudfront.net/insta.webp'
    },
    {
        link: 'https://www.facebook.com/profile.php?id=100082781024453',
        image: 'https://d3r4ipxr51o1re.cloudfront.net/facebook.webp'
    },
    {
        link: 'https://www.tiktok.com/@moneyblinks.ec',
        image: 'https://d3r4ipxr51o1re.cloudfront.net/tiktok.webp'
    },
    {
        link: 'https://youtube.com/@moneyblinks9734',
        image: 'https://d3r4ipxr51o1re.cloudfront.net/youtube.webp'
    }
]