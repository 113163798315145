const hardPasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/; // al menos una mayus, una minus, un numero y un caracter especial
const basicPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d).+$/; //al menos una letra, al menos un número, puede tener caracteres especiales
const mediumPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])[a-zA-Z\d!@#\$%\^&\*]{8,}$/; //al menos una letra, un número y un caracter especial
const basicEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const mediumEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const strongEmailRegex =
  /^(?=.{1,256}$)(?=.{1,64}@)([a-zA-Z0-9_\-\.!#\$%&'\*\+/=\?\^`\{\}\|~]+)(@[a-zA-Z0-9_\-]{1,63}\.)[a-zA-Z]{2,}(\.[a-zA-Z]+){0,3}$/;

export const YUP_REGEX = {
  PASSWORD: {
    BASIC: basicPasswordRegex,
    MEDIUM: mediumPasswordRegex,
    STRONG: hardPasswordRegex,
  },
  EMAIL: {
    BASIC: basicEmailRegex,
    MEDIUM: mediumEmailRegex,
    STRONG: strongEmailRegex,
  },
  USERNAME: /^([a-z]+[0-9|a-z|.]*){4,16}$/,
  ONLY_NUMBERS: /^\d+$/,
  ANY_CHARS: /^(?=.[^\\s])[\w\W]+$/,
  EXPIRATION_DATE_CARD: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
  ESPECIAL_CHARS_NAME: /^[A-ZÁÉÍÓÚÑ]+(\s[A-ZÁÉÍÓÚÑ]+)*$/i,
  CARD_NUMBER: /^[0-9]{15,16}|(([0-9]{4}\s){3}[0-9]{3,4})$/,
  CARD_NUMBER_DUPLICATED: /(\d)\1{3,}/,
  AMOUNT_FLOAT: /^\d+(?:\.\d+)?$/,
  AT_LEST_ONE_CHAR: /[^\s]+/,
  ALPHA_NUMERIC: /^[0-9a-zA-Z\s]+$/,
  ONLY_TEXT_SPACE: /^[a-zA-Z\s]+$/,
  PHONE_NUMBER: /^[0-9]{8,}$/
};
