import { useEffect, useRef, useState } from "react";
export const useTimer = ({
    sizeTimer
}) => {
    const [currentSeconds, setCurrentSeconds] = useState(0);
    const [remainingTime, setRemainingTime] = useState('0');
    const [canRetry, setCanRetry] = useState(false);

    let currentInterval = useRef(null)
    useEffect(() => {
        handleResetTimer(sizeTimer)
    }, [sizeTimer])

    useEffect(() => {
        if (currentInterval.current) {
            clearInterval(currentInterval.current)
        }
        currentInterval.current = setInterval(handleRemainingSeconds, 1000)
        handleRemainingTime()
    }, [currentSeconds])
    const handleResetTimer = (time) => {
        if (typeof time == "number") {
            setCanRetry(false)
            setCurrentSeconds(parseInt(`${time}`))
        } else {
            setCurrentSeconds(0)
        }
    }
    const handleRemainingSeconds = () => {
        if (currentSeconds <= 0) {
            if (currentInterval.current) clearInterval(currentInterval.current)
            setCanRetry(true)
            return;
        }
        setCurrentSeconds((prevState) => prevState - 1)
    }
    const handleRemainingTime = () => {
        try {
            if (currentSeconds <= 0) {
                setRemainingTime('')
                return
            }
            const horas = Math.floor(currentSeconds / 3600);
            const minutos = Math.floor((currentSeconds % 3600) / 60);
            const segundosRestantes = currentSeconds % 60;

            let resultado = "";
            if (horas > 0) {
                resultado += `${horas} hora${horas > 1 ? "s" : ""}, `;
            }
            if (minutos > 0) {
                resultado += `${minutos} minuto${minutos > 1 ? "s" : ""}, `;
            }
            resultado += `${segundosRestantes} segundo${segundosRestantes > 1 ? "s" : ""}`;
            setRemainingTime(` ${resultado}`)
        } catch (e) {
            setRemainingTime('0')
        }

    }

    return {
        handleResetTimer,
        remainingTime,
        canRetry
    }
}