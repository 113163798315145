import React from "react";
import { TEXT_PROPERTIES as THEME } from './config/Config';
import { THEME_COLORS } from "./config/Theme";
import { motion } from "framer-motion";
// type SizeProps =
//   | {
//       heading?: never;
//       title?: boolean;
//       subtitle?: boolean;
//       subheading?: never;
//       label?: never;
//       size?: never;
//     }
//   | {
//       heading?: never;
//       title?: boolean;
//       subtitle?: never;
//       subheading?: never;
//       label?: never;
//       size?: never;
//     }
//   | {
//       heading?: boolean;
//       title?: never;
//       subtitle?: never;
//       subheading?: never;
//       label?: never;
//       size?: never;
//     }
//   | {
//       heading?: never;
//       title?: never;
//       subtitle?: never;
//       subheading?: boolean;
//       label?: never;
//       size?: never;
//     }
//   | {
//       heading?: never;
//       title?: never;
//       subtitle?: never;
//       subheading?: never;
//       label?: boolean;
//       size?: never;
//     }
//   | {
//       heading?: never;
//       title?: never;
//       subtitle?: never;
//       subheading?: never;
//       label?: never;
//       size: "heading" | "subheading" | "title" | "subtitle" | "label" | "small";
//     };
// type WeightProps =
//   | {
//       bold?: boolean;
//       bolder?: never;
//       light?: never;
//       boldest?: never;
//       weight?: never;
//     }
//   | {
//       bold?: never;
//       bolder?: boolean;
//       light?: never;
//       boldest?: never;
//       weight?: never;
//     }
//   | {
//       bold?: never;
//       bolder?: never;
//       light?: boolean;
//       boldest?: never;
//       weight?: never;
//     }
//   | {
//       bold?: never;
//       bolder?: never;
//       light?: never;
//       boldest?: boolean;
//       weight?: never;
//     }
//   | {
//       weight?:
//         | "100"
//         | "200"
//         | "300"
//         | "400"
//         | "500"
//         | "600"
//         | "700"
//         | "800"
//         | "900";
//       bold?: never;
//       bolder?: never;
//       light?: never;
//       boldest?: never;
//     };

// type PositionProps =
//   | { center?: never; right?: boolean; justify?: never }
//   | { center?: boolean; right?: never; justify?: never }
//   | { center?: never; right?: never; justify?: boolean };
// type StyleProps = {
//   italic?: boolean;
// };
// type DecorationProps = { decoration?: "underline" | "wavy" | "dotted" };
// type TransformProps = {
//   transform?: "lowercase" | "uppercase" | "capitalize";
// };
// type TextProps = PositionProps &
//   WeightProps &
//   StyleProps &
//   DecorationProps &
//   TransformProps &
//   SizeProps & {
//     children: string | string[];
//     color?: string;
//   } & DefaultComponentProps;

export default function StyledText({
    title,
    bold,
    center,
    heading,
    subheading,
    right,
    children,
    subtitle,
    justify,
    bolder,
    boldest,
    light,
    weight,
    italic,
    color,
    label,
    decoration,
    transform,
    style,
    className,
    size,
    fontFamily,
    gray,
    grayDark,
    animationTo,
    animationDuration
}//: TextProps
) {

    let _style = {};
    let _animationVariants = {
        hidden: {
            x: 0,
            y: 0,
            opacity: 0,
        },
        visible: {
            x: 0,
            y: 0,
            opacity: 0,
        },
    }
    //size
    switch (size) {
        case "heading": {
            _style.fontSize = THEME.FONT_SIZE.HEADING;
            break;
        }
        case "subheading": {
            _style.fontSize = THEME.FONT_SIZE.SUBHEADING;
            break;
        }
        case "title": {
            _style.fontSize = THEME.FONT_SIZE.TITLE;
            break;
        }
        case "subtitle": {
            _style.fontSize = THEME.FONT_SIZE.SUBTITLE;
            break;
        }
        case "label": {
            _style.fontSize = THEME.FONT_SIZE.LABEL;
            break;
        }
        case "small": {
            _style.fontSize = THEME.FONT_SIZE.SMALL;
            break;
        }
        case "large": {
            _style.fontSize = THEME.FONT_SIZE.LARGE;
            break;
        }
        case "medium": {
            _style.fontSize = THEME.FONT_SIZE.MEDIUM;
            break;
        }
        default: {
            if (title) _style.fontSize = THEME.FONT_SIZE.TITLE;
            else if (subtitle) _style.fontSize = THEME.FONT_SIZE.SUBTITLE;
            else if (heading) _style.fontSize = THEME.FONT_SIZE.HEADING;
            else if (subheading) _style.fontSize = THEME.FONT_SIZE.SUBHEADING;
            else if (label) _style.fontSize = THEME.FONT_SIZE.LABEL;
        }
    }

    //position
    if (center) _style.textAlign = THEME.TEXT_ALIGN.CENTER;// as CanvasTextAlign;
    else if (right) _style.textAlign = THEME.TEXT_ALIGN.RIGHT;// as CanvasTextAlign;
    else if (justify)
        _style.textAlign = THEME.TEXT_ALIGN.JUSTIFY;//as CanvasTextAlign;

    //weight
    if (bold) _style.fontWeight = THEME.FONT_WEIGHT.BOLD;
    else if (bolder) _style.fontWeight = THEME.FONT_WEIGHT.BOLDER;
    else if (boldest) _style.fontWeight = THEME.FONT_WEIGHT.BOLDEST;
    else if (light) _style.fontWeight = THEME.FONT_WEIGHT.LIGHT;
    else if (weight) _style.fontWeight = weight;
    //style
    if (italic) _style.fontStyle = THEME.TEXT_DECORATION.ITALIC;
    //decoration
    if (decoration === "underline")
        _style.textDecoration = THEME.TEXT_DECORATION.UNDERLINE;
    else if (decoration === "wavy")
        _style.textDecoration = THEME.TEXT_DECORATION.WAVY;
    else if (decoration === "dotted")
        _style.textDecoration = THEME.TEXT_DECORATION.DOTTED;

    _style.textTransform = transform;


    //colors
    if (gray) _style.color = THEME_COLORS.GRAY;
    if (grayDark) _style.color = THEME_COLORS.GRAYDARK;
    if (style) {
        _style = { ..._style, ...style };
    }
    //custom colors
    if (color) _style.color = color;
    //animation
    switch (animationTo) {
        case "left": {
            _animationVariants.hidden.x = 180;
            _animationVariants.visible.opacity = 1;
            break;
        }
        case "top": {
            _animationVariants.hidden.y = 180;
            _animationVariants.visible.opacity = 1;
            break;
        }
        case "rigth": {
            _animationVariants.hidden.x = -180;
            _animationVariants.visible.opacity = 1;
            break;
        }
        case "bottom": {
            _animationVariants.hidden.y = -180;
            _animationVariants.visible.opacity = 1;
            break;
        }
        default: {
            _animationVariants.hidden.y = 1;
            _animationVariants.visible.opacity = 1;
        }
    }
    return (<motion.div
        variants={_animationVariants}
        initial={animationTo ? 'hidden' : 'visible'}
        animate={(custom) => ((custom) ? "visible" : "hidden")}
        custom={true}
        transition={{ duration: animationDuration || 0.4, ease: "easeInOut" }}
    >
        <h1 className={className} style={_style}>
            {children}
        </h1>
    </motion.div>)
}
