export const byReferralCodeInfo = /* GraphQL */ `
  query ByReferralCode(
    $code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBReferralCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byReferralCode(
      code: $code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        updatedAt
        usedAt
        usedBy
        userID
        user {
            fullName
            id
            avatarUrl
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;