/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const byAlpha2CodePaymentMethodC = /* GraphQL */ `
  query ByAlpha2CodePaymentMethodC(
    $alpha2Code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBPaymentMethodCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byAlpha2CodePaymentMethodC(
      alpha2Code: $alpha2Code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byAlpha3CodePaymentMethodC = /* GraphQL */ `
  query ByAlpha3CodePaymentMethodC(
    $alpha3Code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBPaymentMethodCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byAlpha3CodePaymentMethodC(
      alpha3Code: $alpha3Code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byBlinkCountryId = /* GraphQL */ `
  query ByBlinkCountryId(
    $countryID: ID!
    $filter: ModelMBBlinkSettingsFilterInput
    $isoStateCode: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byBlinkCountryId(
      countryID: $countryID
      filter: $filter
      isoStateCode: $isoStateCode
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        blinkCost
        countryID
        createdAt
        currency
        deletedAt
        id
        isoStateCode
        promotionalCount
        settings
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byBlinksGetUser = /* GraphQL */ `
  query ByBlinksGetUser(
    $blinkSettingID: ID!
    $filter: ModelMBBlinkUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: ModelIDKeyConditionInput
  ) {
    byBlinksGetUser(
      blinkSettingID: $blinkSettingID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        blinkAcquired
        blinkAvailable
        blinkPrice
        blinkSettingID
        createdAt
        currency
        deletedAt
        id
        isPromotional
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byCardIdUserBatch = /* GraphQL */ `
  query ByCardIdUserBatch(
    $cardID: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserBatchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byCardIdUserBatch(
      cardID: $cardID
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cardID
        createdAt
        deletedAt
        id
        settings
        updatedAt
        userID
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byCode = /* GraphQL */ `
  query ByCode(
    $code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byCode(
      code: $code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byCostCenter = /* GraphQL */ `
  query ByCostCenter(
    $availableFromAt: ModelStringKeyConditionInput
    $costCenterID: ID!
    $filter: ModelMBPaymentMethodCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byCostCenter(
      availableFromAt: $availableFromAt
      costCenterID: $costCenterID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byEmail = /* GraphQL */ `
  query ByEmail(
    $createdAt: ModelStringKeyConditionInput
    $email: AWSEmail!
    $filter: ModelMBUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byEmail(
      createdAt: $createdAt
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byIdentification = /* GraphQL */ `
  query ByIdentification(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserFilterInput
    $identificationNumber: String!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byIdentification(
      createdAt: $createdAt
      filter: $filter
      identificationNumber: $identificationNumber
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byNickname = /* GraphQL */ `
  query ByNickname(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserFilterInput
    $limit: Int
    $nextToken: String
    $nickname: String!
    $sortDirection: ModelSortDirection
  ) {
    byNickname(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      nickname: $nickname
      sortDirection: $sortDirection
    ) {
      items {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byPaymentMethod = /* GraphQL */ `
  query ByPaymentMethod(
    $availableFromAt: ModelStringKeyConditionInput
    $filter: ModelMBPaymentMethodCountryFilterInput
    $limit: Int
    $nextToken: String
    $paymentMethodID: ID!
    $sortDirection: ModelSortDirection
  ) {
    byPaymentMethod(
      availableFromAt: $availableFromAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      paymentMethodID: $paymentMethodID
      sortDirection: $sortDirection
    ) {
      items {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byPaymentUser = /* GraphQL */ `
  query ByPaymentUser(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBMyPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: ID!
  ) {
    byPaymentUser(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byPhoneNumber = /* GraphQL */ `
  query ByPhoneNumber(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserFilterInput
    $limit: Int
    $nextToken: String
    $phoneNumber: String!
    $sortDirection: ModelSortDirection
  ) {
    byPhoneNumber(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      phoneNumber: $phoneNumber
      sortDirection: $sortDirection
    ) {
      items {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byReferralCode = /* GraphQL */ `
  query ByReferralCode(
    $code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBReferralCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    byReferralCode(
      code: $code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        updatedAt
        usedAt
        usedBy
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byThirdCountryPartyID = /* GraphQL */ `
  query ByThirdCountryPartyID(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBThirdAccountsPayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $thirdCountryPartyID: String!
  ) {
    byThirdCountryPartyID(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      thirdCountryPartyID: $thirdCountryPartyID
    ) {
      items {
        createdAt
        id
        settings
        thirdCountryPartyID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byUserId = /* GraphQL */ `
  query ByUserId(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBCorresponsalSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userId: String!
  ) {
    byUserId(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userId: $userId
    ) {
      items {
        createdAt
        id
        updatedAt
        userId
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byUserIdBlinks = /* GraphQL */ `
  query ByUserIdBlinks(
    $blinkSettingID: ModelIDKeyConditionInput
    $filter: ModelMBBlinkUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: ID!
  ) {
    byUserIdBlinks(
      blinkSettingID: $blinkSettingID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        blinkAcquired
        blinkAvailable
        blinkPrice
        blinkSettingID
        createdAt
        currency
        deletedAt
        id
        isPromotional
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const costCenterAlpha2Code = /* GraphQL */ `
  query CostCenterAlpha2Code(
    $alpha2Code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    costCenterAlpha2Code(
      alpha2Code: $alpha2Code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const costCenterAlpha3Code = /* GraphQL */ `
  query CostCenterAlpha3Code(
    $alpha3Code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBCountryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    costCenterAlpha3Code(
      alpha3Code: $alpha3Code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const downloadByTx = /* GraphQL */ `
  query DownloadByTx(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBDownloadBlinkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $txID: ID!
  ) {
    downloadByTx(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      txID: $txID
    ) {
      items {
        amount
        batchCatch
        createdAt
        currency
        deletedAt
        id
        paymentMethod
        paymentMethodId
        processAt
        stateCode
        txID
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckbookDataByUserID = /* GraphQL */ `
  query GetCheckbookDataByUserID(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBCheckbookUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: ID!
  ) {
    getCheckbookDataByUserID(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        checkbookData
        createdAt
        deletedAt
        id
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorresponsalTxByCode = /* GraphQL */ `
  query GetCorresponsalTxByCode(
    $code: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBCorresponsalTxFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getCorresponsalTxByCode(
      code: $code
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        amount
        amountwc
        code
        commission
        corresponsalID
        createdAt
        customerID
        id
        transactionType
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorresponsalTxByCorresponsalID = /* GraphQL */ `
  query GetCorresponsalTxByCorresponsalID(
    $corresponsalID: String!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBCorresponsalTxFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getCorresponsalTxByCorresponsalID(
      corresponsalID: $corresponsalID
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        amount
        amountwc
        code
        commission
        corresponsalID
        createdAt
        customerID
        id
        transactionType
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorresponsalTxByCustomerID = /* GraphQL */ `
  query GetCorresponsalTxByCustomerID(
    $createdAt: ModelStringKeyConditionInput
    $customerID: String!
    $filter: ModelMBCorresponsalTxFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getCorresponsalTxByCustomerID(
      createdAt: $createdAt
      customerID: $customerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        amount
        amountwc
        code
        commission
        corresponsalID
        createdAt
        customerID
        id
        transactionType
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpBackOffByEntId = /* GraphQL */ `
  query GetExpBackOffByEntId(
    $createdAt: ModelStringKeyConditionInput
    $entityId: String!
    $filter: ModelMBExponentialBackoffFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getExpBackOffByEntId(
      createdAt: $createdAt
      entityId: $entityId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        currentRetry
        deletedAt
        entityId
        expiresAt
        id
        maxRetry
        resourceName
        resourceType
        secondEntityId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExpBackOffByResType = /* GraphQL */ `
  query GetExpBackOffByResType(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBExponentialBackoffFilterInput
    $limit: Int
    $nextToken: String
    $resourceType: String!
    $sortDirection: ModelSortDirection
  ) {
    getExpBackOffByResType(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      resourceType: $resourceType
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        currentRetry
        deletedAt
        entityId
        expiresAt
        id
        maxRetry
        resourceName
        resourceType
        secondEntityId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFileUploadBank = /* GraphQL */ `
  query GetFileUploadBank($id: ID!) {
    getFileUploadBank(id: $id) {
      createdAt
      fileName
      fileType
      id
      isDownload
      location
      processedAt
      processedBy
      type
      updatedAt
      __typename
    }
  }
`;
export const getMBBlinkCostByPrice = /* GraphQL */ `
  query GetMBBlinkCostByPrice($id: ID!) {
    getMBBlinkCostByPrice(id: $id) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const getMBBlinkPay = /* GraphQL */ `
  query GetMBBlinkPay($id: ID!) {
    getMBBlinkPay(id: $id) {
      blink {
        blinkAcquired
        blinkAvailable
        blinkPrice
        blinkSettingID
        createdAt
        currency
        deletedAt
        id
        isPromotional
        updatedAt
        userID
        __typename
      }
      blinkID
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBBlinkSettings = /* GraphQL */ `
  query GetMBBlinkSettings($id: ID!) {
    getMBBlinkSettings(id: $id) {
      blinkCost
      blinksByUser {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isoStateCode
      promotionalCount
      settings
      type
      updatedAt
      __typename
    }
  }
`;
export const getMBBlinkUser = /* GraphQL */ `
  query GetMBBlinkUser($id: ID!) {
    getMBBlinkUser(id: $id) {
      blinkAcquired
      blinkAvailable
      blinkPrice
      blinkSetting {
        blinkCost
        countryID
        createdAt
        currency
        deletedAt
        id
        isoStateCode
        promotionalCount
        settings
        type
        updatedAt
        __typename
      }
      blinkSettingID
      createdAt
      currency
      deletedAt
      id
      isPromotional
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBCard = /* GraphQL */ `
  query GetMBCard($id: ID!) {
    getMBCard(id: $id) {
      cardId
      cardNumber
      createdAt
      currencyCode
      description
      expirationDate
      id
      isActive
      isUsedPayment
      label
      nameCard
      payType
      paymentMethod
      paymentMethodCountryID
      transactionType
      updatedAt
      userID
      __typename
    }
  }
`;
export const getMBChargeCountry = /* GraphQL */ `
  query GetMBChargeCountry($id: ID!) {
    getMBChargeCountry(id: $id) {
      chargeCode
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const getMBCheckbookUser = /* GraphQL */ `
  query GetMBCheckbookUser($id: ID!) {
    getMBCheckbookUser(id: $id) {
      checkbookData
      createdAt
      deletedAt
      id
      updatedAt
      userID
      __typename
    }
  }
`;
export const getMBCode = /* GraphQL */ `
  query GetMBCode($id: ID!) {
    getMBCode(id: $id) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      isUserUsed
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBContact = /* GraphQL */ `
  query GetMBContact($id: ID!) {
    getMBContact(id: $id) {
      code
      codeID
      createdAt
      deletedAt
      id
      invited {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      invitedID
      invitingID
      isFavorite
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      myReceipts
      myShipments
      updatedAt
      __typename
    }
  }
`;
export const getMBCorresponsalChargues = /* GraphQL */ `
  query GetMBCorresponsalChargues($id: ID!) {
    getMBCorresponsalChargues(id: $id) {
      blinkCost
      createdAt
      deletedAt
      id
      range
      updatedAt
      __typename
    }
  }
`;
export const getMBCorresponsalSettings = /* GraphQL */ `
  query GetMBCorresponsalSettings($id: ID!) {
    getMBCorresponsalSettings(id: $id) {
      createdAt
      id
      updatedAt
      userId
      value
      __typename
    }
  }
`;
export const getMBCorresponsalTx = /* GraphQL */ `
  query GetMBCorresponsalTx($id: ID!) {
    getMBCorresponsalTx(id: $id) {
      amount
      amountwc
      code
      commission
      corresponsal {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      corresponsalID
      createdAt
      customer {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      customerID
      id
      transactionType
      updatedAt
      __typename
    }
  }
`;
export const getMBCountry = /* GraphQL */ `
  query GetMBCountry($id: ID!) {
    getMBCountry(id: $id) {
      alpha2Code
      alpha3Code
      charges {
        nextToken
        __typename
      }
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryStateId
      createdAt
      currency
      deletedAt
      id
      isActive
      isDownload
      name
      settings
      showOrder
      taxes {
        nextToken
        __typename
      }
      translate
      type
      updatedAt
      __typename
    }
  }
`;
export const getMBDeletedUser = /* GraphQL */ `
  query GetMBDeletedUser($id: ID!) {
    getMBDeletedUser(id: $id) {
      createdAt
      deletedAt
      id
      reason
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBDownloadBlink = /* GraphQL */ `
  query GetMBDownloadBlink($id: ID!) {
    getMBDownloadBlink(id: $id) {
      amount
      batchCatch
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      paymentMethodId
      processAt
      stateCode
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBEcpTransaction = /* GraphQL */ `
  query GetMBEcpTransaction($id: ID!) {
    getMBEcpTransaction(id: $id) {
      amount
      charges
      countryReceiverID
      createdAt
      deletedAt
      details
      ecpTransactionID
      id
      isMBUser
      paymentMethod
      paymentType
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      total
      transactionID
      updatedAt
      __typename
    }
  }
`;
export const getMBExponentialBackoff = /* GraphQL */ `
  query GetMBExponentialBackoff($id: ID!) {
    getMBExponentialBackoff(id: $id) {
      createdAt
      currentRetry
      deletedAt
      entityId
      expiresAt
      id
      maxRetry
      resourceName
      resourceType
      secondEntityId
      updatedAt
      __typename
    }
  }
`;
export const getMBFinancialData = /* GraphQL */ `
  query GetMBFinancialData($id: ID!) {
    getMBFinancialData(id: $id) {
      amount
      blinks
      createdAt
      currency
      deletedAt
      id
      isActive
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBFinancialDataByUserID = /* GraphQL */ `
  query GetMBFinancialDataByUserID(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBFinancialDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: ID!
  ) {
    getMBFinancialDataByUserID(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        amount
        blinks
        createdAt
        currency
        deletedAt
        id
        isActive
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMBInsuredUsers = /* GraphQL */ `
  query GetMBInsuredUsers($id: ID!) {
    getMBInsuredUsers(id: $id) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      id
      insuranceID
      insured
      typeInsurance
      updatedAt
      __typename
    }
  }
`;
export const getMBLifeInsurance = /* GraphQL */ `
  query GetMBLifeInsurance($id: ID!) {
    getMBLifeInsurance(id: $id) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const getMBMedInsurance = /* GraphQL */ `
  query GetMBMedInsurance($id: ID!) {
    getMBMedInsurance(id: $id) {
      beneficiary
      beneficiaryID
      code
      createdAt
      deletedAt
      expiresAt
      id
      insuranceID
      insured
      updatedAt
      __typename
    }
  }
`;
export const getMBMembershipCategory = /* GraphQL */ `
  query GetMBMembershipCategory($id: ID!) {
    getMBMembershipCategory(id: $id) {
      categoryType
      createdAt
      deletedAt
      id
      isActive
      settings
      showOrder
      updatedAt
      __typename
    }
  }
`;
export const getMBMyPaymentMethod = /* GraphQL */ `
  query GetMBMyPaymentMethod($id: ID!) {
    getMBMyPaymentMethod(id: $id) {
      accountId
      createdAt
      deletedAt
      description
      id
      isActive
      isDeleted
      isUsedPayment
      label
      payType
      paymentMethodCountry {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      paymentMethodCountryID
      settings
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      value
      __typename
    }
  }
`;
export const getMBMyThirdAccountsPay = /* GraphQL */ `
  query GetMBMyThirdAccountsPay($id: ID!) {
    getMBMyThirdAccountsPay(id: $id) {
      accountId
      alpha2Code
      alpha3Code
      createdAt
      description
      id
      isActive
      label
      settings
      type
      updatedAt
      userID
      __typename
    }
  }
`;
export const getMBNotification = /* GraphQL */ `
  query GetMBNotification($id: ID!) {
    getMBNotification(id: $id) {
      createdAt
      data
      id
      isRead
      message
      title
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBOwnAccountsTransactions = /* GraphQL */ `
  query GetMBOwnAccountsTransactions($id: ID!) {
    getMBOwnAccountsTransactions(id: $id) {
      CountryPaymentMethod
      amount
      bankReceiver
      countryReceiver
      createdAt
      currency
      deletedAt
      id
      paymentMethod
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const getMBPay = /* GraphQL */ `
  query GetMBPay($id: ID!) {
    getMBPay(id: $id) {
      amount
      createdAt
      currency
      deletedAt
      exchangeAmount
      exchangeCurrency
      exchangeRate
      id
      paymentID
      paymentMethod {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      paymentMethodCountry
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBPaymentMethod = /* GraphQL */ `
  query GetMBPaymentMethod($id: ID!) {
    getMBPaymentMethod(id: $id) {
      code
      countries {
        nextToken
        __typename
      }
      createdAt
      deletedAt
      id
      name
      translate
      updatedAt
      __typename
    }
  }
`;
export const getMBPaymentMethodCountry = /* GraphQL */ `
  query GetMBPaymentMethodCountry($id: ID!) {
    getMBPaymentMethodCountry(id: $id) {
      alpha2Code
      alpha3Code
      availableFromAt
      availableUntilAt
      costCenter {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      costCenterID
      createdAt
      id
      isActive
      isReceipt
      isShipping
      paymentMethod {
        code
        createdAt
        deletedAt
        id
        name
        translate
        updatedAt
        __typename
      }
      paymentMethodID
      paymentTypeCode
      settings
      updatedAt
      users {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const getMBReferralCode = /* GraphQL */ `
  query GetMBReferralCode($id: ID!) {
    getMBReferralCode(id: $id) {
      code
      codeType
      createdAt
      deletedAt
      id
      isUsed
      updatedAt
      usedAt
      usedBy
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBSettings = /* GraphQL */ `
  query GetMBSettings($id: ID!) {
    getMBSettings(id: $id) {
      alpha2Code
      alpha3Code
      createdAt
      deletedAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const getMBSettingsApi = /* GraphQL */ `
  query GetMBSettingsApi($id: ID!) {
    getMBSettingsApi(id: $id) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      platform
      settings
      updatedAt
      __typename
    }
  }
`;
export const getMBSettingsCard = /* GraphQL */ `
  query GetMBSettingsCard($id: ID!) {
    getMBSettingsCard(id: $id) {
      createdAt
      deletedAt
      id
      isActive
      isUseToPay
      settings
      type
      typeCard
      updatedAt
      __typename
    }
  }
`;
export const getMBTaxCountry = /* GraphQL */ `
  query GetMBTaxCountry($id: ID!) {
    getMBTaxCountry(id: $id) {
      country {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      countryID
      createdAt
      currency
      deletedAt
      id
      isActive
      isBlinkPay
      isReceipt
      isShipping
      settings
      showOrder
      taxCode
      total
      translate
      updatedAt
      __typename
    }
  }
`;
export const getMBThirdAccountsPay = /* GraphQL */ `
  query GetMBThirdAccountsPay($id: ID!) {
    getMBThirdAccountsPay(id: $id) {
      createdAt
      id
      settings
      thirdCountryPartyID
      updatedAt
      __typename
    }
  }
`;
export const getMBThirdAccountsTransactions = /* GraphQL */ `
  query GetMBThirdAccountsTransactions($id: ID!) {
    getMBThirdAccountsTransactions(id: $id) {
      CountryPaymentMethod
      amount
      bankReceiver
      batchCatch
      countryReceiver
      createdAt
      currency
      id
      paymentMethod
      processAt
      shippingID
      type
      updatedAt
      __typename
    }
  }
`;
export const getMBThirdPartyCountries = /* GraphQL */ `
  query GetMBThirdPartyCountries($id: ID!) {
    getMBThirdPartyCountries(id: $id) {
      alpha2Code
      alpha3Code
      createdAt
      id
      isActive
      isUsedPayment
      settings
      updatedAt
      __typename
    }
  }
`;
export const getMBTransaction = /* GraphQL */ `
  query GetMBTransaction($id: ID!) {
    getMBTransaction(id: $id) {
      amount
      amountDeposit
      charges
      codeID
      createdAt
      currency
      currencyDeposit
      deletedAt
      downloads {
        nextToken
        __typename
      }
      id
      isConfirm
      isReceipt
      message
      moneyBlinksCode {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      receipt {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      receiptID
      requestMessage
      shipping {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      shippingID
      taxes
      txStatus
      txType
      txValues
      type
      typeTransaction
      updatedAt
      __typename
    }
  }
`;
export const getMBTransactionPay = /* GraphQL */ `
  query GetMBTransactionPay($id: ID!) {
    getMBTransactionPay(id: $id) {
      createdAt
      deletedAt
      id
      payID
      payment {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      tx {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      txID
      type
      updatedAt
      user {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      userID
      __typename
    }
  }
`;
export const getMBUser = /* GraphQL */ `
  query GetMBUser($id: ID!) {
    getMBUser(id: $id) {
      acceptedPromotionalInfo
      acceptedRequestBlink
      address
      alpha2Code
      alpha3Code
      avatarUrl
      birthDate
      checkEmail
      checkPhone
      city
      cognitoUserId
      country
      createdAt
      currency
      deletedAt
      deviceToken
      email
      fullName
      id
      idcheckbookID
      identificationBackUrl
      identificationNumber
      identificationType
      identificationUrl
      isAvailabilityTx
      isDeleted
      isMFA
      isTerms
      isUpdateAccount
      isUsedMoneyBlinkAmount
      locale
      myBlinks {
        nextToken
        __typename
      }
      myPayments {
        nextToken
        __typename
      }
      nickname
      oldAvatarUrl
      oldIdentificationBackUrl
      oldIdentificationUrl
      phoneNumber
      role
      smsStatus
      state
      type
      updatedAt
      zipCode
      __typename
    }
  }
`;
export const getMBUserBatch = /* GraphQL */ `
  query GetMBUserBatch($id: ID!) {
    getMBUserBatch(id: $id) {
      cardID
      createdAt
      deletedAt
      id
      settings
      updatedAt
      userID
      username
      __typename
    }
  }
`;
export const getMBUserCodeRegistration = /* GraphQL */ `
  query GetMBUserCodeRegistration($id: ID!) {
    getMBUserCodeRegistration(id: $id) {
      attributes
      codeGenerated
      createdAt
      deletedAt
      email
      id
      nickname
      updatedAt
      __typename
    }
  }
`;
export const getMBUserMemberShip = /* GraphQL */ `
  query GetMBUserMemberShip($id: ID!) {
    getMBUserMemberShip(id: $id) {
      categoryType
      createdAt
      currentStatus
      deletedAt
      id
      isActive
      settings
      totalBlinksSent
      totalReferral
      totalReferralAccepted
      updatedAt
      userID
      wasBenefited
      __typename
    }
  }
`;
export const getMBUserPartiesAML = /* GraphQL */ `
  query GetMBUserPartiesAML($id: ID!) {
    getMBUserPartiesAML(id: $id) {
      createdAt
      deletedAt
      id
      partyID
      settings
      status
      updatedAt
      userID
      __typename
    }
  }
`;
export const getMBUserVerified = /* GraphQL */ `
  query GetMBUserVerified($id: ID!) {
    getMBUserVerified(id: $id) {
      apiResultCode
      apiResultMessage
      counterRequested
      createdAt
      deletedAt
      emailSent
      errorCode
      id
      status
      transactionID
      typeVerification
      updatedAt
      userID
      verified
      verifiedBy
      workflowID
      __typename
    }
  }
`;
export const getMembershipStatusByUserId = /* GraphQL */ `
  query GetMembershipStatusByUserId(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserMemberShipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: ID!
  ) {
    getMembershipStatusByUserId(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        categoryType
        createdAt
        currentStatus
        deletedAt
        id
        isActive
        settings
        totalBlinksSent
        totalReferral
        totalReferralAccepted
        updatedAt
        userID
        wasBenefited
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserBytransactionID = /* GraphQL */ `
  query GetUserBytransactionID(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserVerifiedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $transactionID: String!
  ) {
    getUserBytransactionID(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      transactionID: $transactionID
    ) {
      items {
        apiResultCode
        apiResultMessage
        counterRequested
        createdAt
        deletedAt
        emailSent
        errorCode
        id
        status
        transactionID
        typeVerification
        updatedAt
        userID
        verified
        verifiedBy
        workflowID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserCodeRegistrationByNickname = /* GraphQL */ `
  query GetUserCodeRegistrationByNickname(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserCodeRegistrationFilterInput
    $limit: Int
    $nextToken: String
    $nickname: String!
    $sortDirection: ModelSortDirection
  ) {
    getUserCodeRegistrationByNickname(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      nickname: $nickname
      sortDirection: $sortDirection
    ) {
      items {
        attributes
        codeGenerated
        createdAt
        deletedAt
        email
        id
        nickname
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserVerifiedByUserID = /* GraphQL */ `
  query GetUserVerifiedByUserID(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserVerifiedFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: String!
  ) {
    getUserVerifiedByUserID(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        apiResultCode
        apiResultMessage
        counterRequested
        createdAt
        deletedAt
        emailSent
        errorCode
        id
        status
        transactionID
        typeVerification
        updatedAt
        userID
        verified
        verifiedBy
        workflowID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lastBlinkSettings = /* GraphQL */ `
  query LastBlinkSettings(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBBlinkSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String!
  ) {
    lastBlinkSettings(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        blinkCost
        countryID
        createdAt
        currency
        deletedAt
        id
        isoStateCode
        promotionalCount
        settings
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAll = /* GraphQL */ `
  query ListAll(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String!
  ) {
    listAll(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAllBlinkPays = /* GraphQL */ `
  query ListAllBlinkPays(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBBlinkPayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String!
  ) {
    listAllBlinkPays(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        blinkID
        createdAt
        deletedAt
        id
        payID
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAllTxPays = /* GraphQL */ `
  query ListAllTxPays(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBTransactionPayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String!
  ) {
    listAllTxPays(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        createdAt
        deletedAt
        id
        payID
        txID
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAllUsers = /* GraphQL */ `
  query ListAllUsers(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String!
  ) {
    listAllUsers(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listByNameOrOrder = /* GraphQL */ `
  query ListByNameOrOrder(
    $filter: ModelMBCountryFilterInput
    $limit: Int
    $nextToken: String
    $showOrder: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $type: String!
  ) {
    listByNameOrOrder(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      showOrder: $showOrder
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listChargesByCountryId = /* GraphQL */ `
  query ListChargesByCountryId(
    $countryID: ID!
    $filter: ModelMBChargeCountryFilterInput
    $limit: Int
    $nextToken: String
    $showOrder: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listChargesByCountryId(
      countryID: $countryID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      showOrder: $showOrder
      sortDirection: $sortDirection
    ) {
      items {
        chargeCode
        countryID
        createdAt
        currency
        deletedAt
        id
        isActive
        isBlinkPay
        isReceipt
        isShipping
        settings
        showOrder
        total
        translate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listFileUploadBanks = /* GraphQL */ `
  query ListFileUploadBanks(
    $filter: ModelFileUploadBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileUploadBanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        fileName
        fileType
        id
        isDownload
        location
        processedAt
        processedBy
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listFilesEC = /* GraphQL */ `
  query ListFilesEC(
    $createdAt: ModelStringKeyConditionInput
    $fileType: String!
    $filter: ModelFileUploadBankFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFilesEC(
      createdAt: $createdAt
      fileType: $fileType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        fileName
        fileType
        id
        isDownload
        location
        processedAt
        processedBy
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBBlinkCostByPrices = /* GraphQL */ `
  query ListMBBlinkCostByPrices(
    $filter: ModelMBBlinkCostByPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBBlinkCostByPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        blinkCost
        createdAt
        deletedAt
        id
        range
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBBlinkPays = /* GraphQL */ `
  query ListMBBlinkPays(
    $filter: ModelMBBlinkPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBBlinkPays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        blinkID
        createdAt
        deletedAt
        id
        payID
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBBlinkSettings = /* GraphQL */ `
  query ListMBBlinkSettings(
    $filter: ModelMBBlinkSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBBlinkSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        blinkCost
        countryID
        createdAt
        currency
        deletedAt
        id
        isoStateCode
        promotionalCount
        settings
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBBlinkUsers = /* GraphQL */ `
  query ListMBBlinkUsers(
    $filter: ModelMBBlinkUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBBlinkUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        blinkAcquired
        blinkAvailable
        blinkPrice
        blinkSettingID
        createdAt
        currency
        deletedAt
        id
        isPromotional
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBCards = /* GraphQL */ `
  query ListMBCards(
    $filter: ModelMBCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        cardId
        cardNumber
        createdAt
        currencyCode
        description
        expirationDate
        id
        isActive
        isUsedPayment
        label
        nameCard
        payType
        paymentMethod
        paymentMethodCountryID
        transactionType
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBChargeCountries = /* GraphQL */ `
  query ListMBChargeCountries(
    $filter: ModelMBChargeCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBChargeCountries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        chargeCode
        countryID
        createdAt
        currency
        deletedAt
        id
        isActive
        isBlinkPay
        isReceipt
        isShipping
        settings
        showOrder
        total
        translate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBCheckbookUsers = /* GraphQL */ `
  query ListMBCheckbookUsers(
    $filter: ModelMBCheckbookUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBCheckbookUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        checkbookData
        createdAt
        deletedAt
        id
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBCodes = /* GraphQL */ `
  query ListMBCodes(
    $filter: ModelMBCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        isUserUsed
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBContacts = /* GraphQL */ `
  query ListMBContacts(
    $filter: ModelMBContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        code
        codeID
        createdAt
        deletedAt
        id
        invitedID
        invitingID
        isFavorite
        myReceipts
        myShipments
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBCorresponsalChargues = /* GraphQL */ `
  query ListMBCorresponsalChargues(
    $filter: ModelMBCorresponsalCharguesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBCorresponsalChargues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        blinkCost
        createdAt
        deletedAt
        id
        range
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBCorresponsalSettings = /* GraphQL */ `
  query ListMBCorresponsalSettings(
    $filter: ModelMBCorresponsalSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBCorresponsalSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        updatedAt
        userId
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBCorresponsalTxes = /* GraphQL */ `
  query ListMBCorresponsalTxes(
    $filter: ModelMBCorresponsalTxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBCorresponsalTxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        amount
        amountwc
        code
        commission
        corresponsalID
        createdAt
        customerID
        id
        transactionType
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBCountries = /* GraphQL */ `
  query ListMBCountries(
    $filter: ModelMBCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        alpha2Code
        alpha3Code
        countryStateId
        createdAt
        currency
        deletedAt
        id
        isActive
        isDownload
        name
        settings
        showOrder
        translate
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBDeletedUsers = /* GraphQL */ `
  query ListMBDeletedUsers(
    $filter: ModelMBDeletedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBDeletedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        deletedAt
        id
        reason
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBDownloadBlinks = /* GraphQL */ `
  query ListMBDownloadBlinks(
    $filter: ModelMBDownloadBlinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBDownloadBlinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        amount
        batchCatch
        createdAt
        currency
        deletedAt
        id
        paymentMethod
        paymentMethodId
        processAt
        stateCode
        txID
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBEcpTransactions = /* GraphQL */ `
  query ListMBEcpTransactions(
    $filter: ModelMBEcpTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBEcpTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        amount
        charges
        countryReceiverID
        createdAt
        deletedAt
        details
        ecpTransactionID
        id
        isMBUser
        paymentMethod
        paymentType
        receiptID
        shippingID
        total
        transactionID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBExponentialBackoffs = /* GraphQL */ `
  query ListMBExponentialBackoffs(
    $filter: ModelMBExponentialBackoffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBExponentialBackoffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        currentRetry
        deletedAt
        entityId
        expiresAt
        id
        maxRetry
        resourceName
        resourceType
        secondEntityId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBFinancialData = /* GraphQL */ `
  query ListMBFinancialData(
    $filter: ModelMBFinancialDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBFinancialData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        amount
        blinks
        createdAt
        currency
        deletedAt
        id
        isActive
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBInsuredUsers = /* GraphQL */ `
  query ListMBInsuredUsers(
    $filter: ModelMBInsuredUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBInsuredUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        beneficiary
        beneficiaryID
        code
        createdAt
        deletedAt
        id
        insuranceID
        insured
        typeInsurance
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBLifeInsurances = /* GraphQL */ `
  query ListMBLifeInsurances(
    $filter: ModelMBLifeInsuranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBLifeInsurances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        beneficiary
        beneficiaryID
        code
        createdAt
        deletedAt
        expiresAt
        id
        insuranceID
        insured
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBMedInsurances = /* GraphQL */ `
  query ListMBMedInsurances(
    $filter: ModelMBMedInsuranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBMedInsurances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        beneficiary
        beneficiaryID
        code
        createdAt
        deletedAt
        expiresAt
        id
        insuranceID
        insured
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBMembershipCategories = /* GraphQL */ `
  query ListMBMembershipCategories(
    $filter: ModelMBMembershipCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBMembershipCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categoryType
        createdAt
        deletedAt
        id
        isActive
        settings
        showOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBMyPaymentMethods = /* GraphQL */ `
  query ListMBMyPaymentMethods(
    $filter: ModelMBMyPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBMyPaymentMethods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBMyThirdAccountsPays = /* GraphQL */ `
  query ListMBMyThirdAccountsPays(
    $filter: ModelMBMyThirdAccountsPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBMyThirdAccountsPays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accountId
        alpha2Code
        alpha3Code
        createdAt
        description
        id
        isActive
        label
        settings
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBNotifications = /* GraphQL */ `
  query ListMBNotifications(
    $filter: ModelMBNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        data
        id
        isRead
        message
        title
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBOwnAccountsTransactions = /* GraphQL */ `
  query ListMBOwnAccountsTransactions(
    $filter: ModelMBOwnAccountsTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBOwnAccountsTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CountryPaymentMethod
        amount
        bankReceiver
        countryReceiver
        createdAt
        currency
        deletedAt
        id
        paymentMethod
        shippingID
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBPaymentMethodCountries = /* GraphQL */ `
  query ListMBPaymentMethodCountries(
    $filter: ModelMBPaymentMethodCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBPaymentMethodCountries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        alpha2Code
        alpha3Code
        availableFromAt
        availableUntilAt
        costCenterID
        createdAt
        id
        isActive
        isReceipt
        isShipping
        paymentMethodID
        paymentTypeCode
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBPaymentMethods = /* GraphQL */ `
  query ListMBPaymentMethods(
    $filter: ModelMBPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBPaymentMethods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        code
        createdAt
        deletedAt
        id
        name
        translate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBPays = /* GraphQL */ `
  query ListMBPays(
    $filter: ModelMBPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBPays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        amount
        createdAt
        currency
        deletedAt
        exchangeAmount
        exchangeCurrency
        exchangeRate
        id
        paymentID
        paymentMethodCountry
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBReferralCodes = /* GraphQL */ `
  query ListMBReferralCodes(
    $filter: ModelMBReferralCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBReferralCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        updatedAt
        usedAt
        usedBy
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBSettings = /* GraphQL */ `
  query ListMBSettings(
    $filter: ModelMBSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        alpha2Code
        alpha3Code
        createdAt
        deletedAt
        id
        isActive
        platform
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBSettingsApis = /* GraphQL */ `
  query ListMBSettingsApis(
    $filter: ModelMBSettingsApiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBSettingsApis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        alpha2Code
        alpha3Code
        createdAt
        id
        isActive
        platform
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBSettingsCards = /* GraphQL */ `
  query ListMBSettingsCards(
    $filter: ModelMBSettingsCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBSettingsCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        deletedAt
        id
        isActive
        isUseToPay
        settings
        type
        typeCard
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBTaxCountries = /* GraphQL */ `
  query ListMBTaxCountries(
    $filter: ModelMBTaxCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBTaxCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        countryID
        createdAt
        currency
        deletedAt
        id
        isActive
        isBlinkPay
        isReceipt
        isShipping
        settings
        showOrder
        taxCode
        total
        translate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBThirdAccountsPays = /* GraphQL */ `
  query ListMBThirdAccountsPays(
    $filter: ModelMBThirdAccountsPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBThirdAccountsPays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        id
        settings
        thirdCountryPartyID
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBThirdAccountsTransactions = /* GraphQL */ `
  query ListMBThirdAccountsTransactions(
    $filter: ModelMBThirdAccountsTransactionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBThirdAccountsTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CountryPaymentMethod
        amount
        bankReceiver
        batchCatch
        countryReceiver
        createdAt
        currency
        id
        paymentMethod
        processAt
        shippingID
        type
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBThirdPartyCountries = /* GraphQL */ `
  query ListMBThirdPartyCountries(
    $filter: ModelMBThirdPartyCountriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBThirdPartyCountries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        alpha2Code
        alpha3Code
        createdAt
        id
        isActive
        isUsedPayment
        settings
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBTransactionPays = /* GraphQL */ `
  query ListMBTransactionPays(
    $filter: ModelMBTransactionPayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBTransactionPays(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        deletedAt
        id
        payID
        txID
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBTransactions = /* GraphQL */ `
  query ListMBTransactions(
    $filter: ModelMBTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        amount
        amountDeposit
        charges
        codeID
        createdAt
        currency
        currencyDeposit
        deletedAt
        id
        isConfirm
        isReceipt
        message
        receiptID
        requestMessage
        shippingID
        taxes
        txStatus
        txType
        txValues
        type
        typeTransaction
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBUserBatches = /* GraphQL */ `
  query ListMBUserBatches(
    $filter: ModelMBUserBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBUserBatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        cardID
        createdAt
        deletedAt
        id
        settings
        updatedAt
        userID
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBUserCodeRegistrations = /* GraphQL */ `
  query ListMBUserCodeRegistrations(
    $filter: ModelMBUserCodeRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBUserCodeRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        attributes
        codeGenerated
        createdAt
        deletedAt
        email
        id
        nickname
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBUserMemberCategory = /* GraphQL */ `
  query ListMBUserMemberCategory(
    $categoryType: String!
    $filter: ModelMBUserMemberShipFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $updatedAt: ModelStringKeyConditionInput
  ) {
    listMBUserMemberCategory(
      categoryType: $categoryType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      updatedAt: $updatedAt
    ) {
      items {
        categoryType
        createdAt
        currentStatus
        deletedAt
        id
        isActive
        settings
        totalBlinksSent
        totalReferral
        totalReferralAccepted
        updatedAt
        userID
        wasBenefited
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBUserMemberShips = /* GraphQL */ `
  query ListMBUserMemberShips(
    $filter: ModelMBUserMemberShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBUserMemberShips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categoryType
        createdAt
        currentStatus
        deletedAt
        id
        isActive
        settings
        totalBlinksSent
        totalReferral
        totalReferralAccepted
        updatedAt
        userID
        wasBenefited
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBUserPartiesAMLS = /* GraphQL */ `
  query ListMBUserPartiesAMLS(
    $filter: ModelMBUserPartiesAMLFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBUserPartiesAMLS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        deletedAt
        id
        partyID
        settings
        status
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBUserVerifieds = /* GraphQL */ `
  query ListMBUserVerifieds(
    $filter: ModelMBUserVerifiedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMBUserVerifieds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        apiResultCode
        apiResultMessage
        counterRequested
        createdAt
        deletedAt
        emailSent
        errorCode
        id
        status
        transactionID
        typeVerification
        updatedAt
        userID
        verified
        verifiedBy
        workflowID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMBUsers = /* GraphQL */ `
  query ListMBUsers(
    $filter: ModelMBUserFilterInput
    $id: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMBUsers(
      filter: $filter
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        acceptedPromotionalInfo
        acceptedRequestBlink
        address
        alpha2Code
        alpha3Code
        avatarUrl
        birthDate
        checkEmail
        checkPhone
        city
        cognitoUserId
        country
        createdAt
        currency
        deletedAt
        deviceToken
        email
        fullName
        id
        idcheckbookID
        identificationBackUrl
        identificationNumber
        identificationType
        identificationUrl
        isAvailabilityTx
        isDeleted
        isMFA
        isTerms
        isUpdateAccount
        isUsedMoneyBlinkAmount
        locale
        nickname
        oldAvatarUrl
        oldIdentificationBackUrl
        oldIdentificationUrl
        phoneNumber
        role
        smsStatus
        state
        type
        updatedAt
        zipCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMemberCategory = /* GraphQL */ `
  query ListMemberCategory(
    $categoryType: String!
    $filter: ModelMBMembershipCategoryFilterInput
    $limit: Int
    $nextToken: String
    $showOrder: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listMemberCategory(
      categoryType: $categoryType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      showOrder: $showOrder
      sortDirection: $sortDirection
    ) {
      items {
        categoryType
        createdAt
        deletedAt
        id
        isActive
        settings
        showOrder
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMyNotifications = /* GraphQL */ `
  query ListMyNotifications(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String!
  ) {
    listMyNotifications(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        createdAt
        data
        id
        isRead
        message
        title
        type
        updatedAt
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listReferralCodeByUserId = /* GraphQL */ `
  query ListReferralCodeByUserId(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBReferralCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userID: ID!
  ) {
    listReferralCodeByUserId(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      userID: $userID
    ) {
      items {
        code
        codeType
        createdAt
        deletedAt
        id
        isUsed
        updatedAt
        usedAt
        usedBy
        userID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaxesByCountryId = /* GraphQL */ `
  query ListTaxesByCountryId(
    $countryID: ID!
    $filter: ModelMBTaxCountryFilterInput
    $limit: Int
    $nextToken: String
    $showOrder: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    listTaxesByCountryId(
      countryID: $countryID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      showOrder: $showOrder
      sortDirection: $sortDirection
    ) {
      items {
        countryID
        createdAt
        currency
        deletedAt
        id
        isActive
        isBlinkPay
        isReceipt
        isShipping
        settings
        showOrder
        taxCode
        total
        translate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const myMethodsByCountry = /* GraphQL */ `
  query MyMethodsByCountry(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBMyPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
    $paymentMethodCountryID: ID!
    $sortDirection: ModelSortDirection
  ) {
    myMethodsByCountry(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      paymentMethodCountryID: $paymentMethodCountryID
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const myRequestsFrequents = /* GraphQL */ `
  query MyRequestsFrequents(
    $filter: ModelMBContactFilterInput
    $invitingID: ID!
    $limit: Int
    $myReceipts: ModelIntKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    myRequestsFrequents(
      filter: $filter
      invitingID: $invitingID
      limit: $limit
      myReceipts: $myReceipts
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        codeID
        createdAt
        deletedAt
        id
        invitedID
        invitingID
        isFavorite
        myReceipts
        myShipments
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const myShipmentsFrequents = /* GraphQL */ `
  query MyShipmentsFrequents(
    $filter: ModelMBContactFilterInput
    $invitingID: ID!
    $limit: Int
    $myShipments: ModelIntKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    myShipmentsFrequents(
      filter: $filter
      invitingID: $invitingID
      limit: $limit
      myShipments: $myShipments
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        codeID
        createdAt
        deletedAt
        id
        invitedID
        invitingID
        isFavorite
        myReceipts
        myShipments
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentByType = /* GraphQL */ `
  query PaymentByType(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelMBMyPaymentMethodFilterInput
    $limit: Int
    $nextToken: String
    $payType: String!
    $sortDirection: ModelSortDirection
  ) {
    paymentByType(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      payType: $payType
      sortDirection: $sortDirection
    ) {
      items {
        accountId
        createdAt
        deletedAt
        description
        id
        isActive
        isDeleted
        isUsedPayment
        label
        payType
        paymentMethodCountryID
        settings
        updatedAt
        userID
        value
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentMethodByCode = /* GraphQL */ `
  query PaymentMethodByCode(
    $code: String!
    $filter: ModelMBPaymentMethodFilterInput
    $limit: Int
    $name: ModelStringKeyConditionInput
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    paymentMethodByCode(
      code: $code
      filter: $filter
      limit: $limit
      name: $name
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        createdAt
        deletedAt
        id
        name
        translate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
