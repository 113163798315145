export const TEXT_PROPERTIES = {
    FONT_SIZE: {
      TITLE: "2rem",
      SUBTITLE: "1.5rem",
      HEADING: "3rem",
      SUBHEADING: "2.5rem",
      LABEL: "1.25rem",
      NORMAL: "auto",
      SMALL: "0.8rem",
      MEDIUM:"1rem",
      LARGE: "1.8rem",
    },
    FONT_WEIGHT: {
      BOLD: "600",
      BOLDER: "700",
      BOLDEST: "900",
      LIGHT: "300",
    },
    TEXT_ALIGN: {
      CENTER: "center",
      RIGHT: "right",
      JUSTIFY: "justify",
    },
    TEXT_DECORATION: {
      ITALIC: "italic",
      UNDERLINE: "underline",
      WAVY: "wavy underline",
      DOTTED: "underline dotted",
    },
  };